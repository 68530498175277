import { IGroupedHubUserMap, IHubUser } from "../../../@types";
import {
  ADD_NEW_HUB_USER_FAILURE,
  ADD_NEW_HUB_USER_REQUEST,
  ADD_NEW_HUB_USER_SUCCESS,
  FAILED_FETCH_HUB_USERS_BY_BRAND,
  FETCHING_HUB_USERS_BY_BRAND,
  RESET_NEW_HUB_USER_REQUEST,
  SELECT_BRAND_FOR_HUB_USER_PREVIEW,
  SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND,
  SEND_HUB_USER_COMMAND_REQUEST,
  SEND_HUB_USER_COMMAND_SUCCESS,
  RESET_HUB_USER_COMMAND_STATE,
  SEND_HUB_USER_COMMAND_FAILURE,
} from "../../../utils/constants";
import { groupBy } from "lodash";
import {
  createNewHubUser,
  deleteHubUserById,
  getHubUserByBrandIdService,
  saveUser,
} from "../../../services/hunUser";
import { Command } from "../../../services/device";

export const actionHubUsersFetchStarted = () => {
  return {
    type: FETCHING_HUB_USERS_BY_BRAND,
  };
};

export const actionFetchedHubUsers = (
  hubUsers: IGroupedHubUserMap,
  brandId: string
) => {
  return {
    type: SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND,
    payload: {
      hubUsers,
      brandId,
    },
  };
};

export const actionFetchHubUsersFailed = (error?: any) => {
  return {
    type: FAILED_FETCH_HUB_USERS_BY_BRAND,
    payload: error,
  };
};

export const actionGetHubUsersForBrand = (brandId: string) => {
  return (dispatch) => {
    dispatch(actionHubUsersFetchStarted());
    return getHubUserByBrandIdService(brandId)
      .then((users) => {
        const usersGroupedById = groupBy(
          users.filter((user) => user?.id),
          "id"
        );
        dispatch(actionFetchedHubUsers(usersGroupedById, brandId));
      })
      .catch((e) => dispatch(actionFetchHubUsersFailed(e)));
  };
};
export const actionAddHubUserRequest = () => {
  return {
    type: ADD_NEW_HUB_USER_REQUEST,
  };
};
export const actionAddHubUserSuccess = (newHubUserName: string) => {
  return {
    type: ADD_NEW_HUB_USER_SUCCESS,
    payload: newHubUserName,
  };
};
export const actionAddHubUserFailed = (error?: any) => {
  return {
    type: ADD_NEW_HUB_USER_FAILURE,
    payload: error,
  };
};
export const actionResetHubUser = () => {
  return {
    type: RESET_NEW_HUB_USER_REQUEST,
    payload: undefined,
  };
};
export const actionAddNewHubUser = (formData: IHubUser) => {
  return (dispatch) => {
    dispatch(actionAddHubUserRequest());
    createNewHubUser(formData)
      .then((newHubUser) => {
        dispatch(actionAddHubUserSuccess(newHubUser));
      })
      .catch((e) => dispatch(actionAddHubUserFailed(e)));
  };
};

export const actionUpdateNewHubUser = (formData: IHubUser) => {
  return (dispatch) => {
    dispatch(actionAddHubUserRequest());
    saveUser(formData)
      .then((newHubUser) => {
        dispatch(actionAddHubUserSuccess(newHubUser));
      })
      .catch((e) => dispatch(actionAddHubUserFailed(e)));
  };
};

export const actionSelectBrandForHubUsersPreview = (brandID?: string) => {
  return {
    type: SELECT_BRAND_FOR_HUB_USER_PREVIEW,
    payload: brandID,
  };
};

export const actionSendHubUserCommandRequest = () => {
  return {
    type: SEND_HUB_USER_COMMAND_REQUEST,
  };
};

export const actionSendHubUserCommandSuccess = (payload: Command) => {
  return {
    type: SEND_HUB_USER_COMMAND_SUCCESS,
    payload,
  };
};

export const actionSendHubUserCommandFailed = (error?: any) => {
  return {
    type: SEND_HUB_USER_COMMAND_FAILURE,
    payload: error,
  };
};

export const actionResetHubUserCommandState = () => {
  return {
    type: RESET_HUB_USER_COMMAND_STATE,
  };
};

export const actionDeleteHubUserById = (
  hubUserID: string,
  command: Command
) => {
  return (dispatch) => {
    dispatch(actionSendHubUserCommandRequest());
    return deleteHubUserById(hubUserID)
      .then((data) => {
        dispatch(actionSendHubUserCommandSuccess(command));
      })
      .catch((e) => dispatch(actionSendHubUserCommandFailed(e)));
  };
};
