import {
  FETCH_STORES_BY_BRAND_FAILURE,
  FETCH_STORES_BY_BRAND_REQUEST,
  FETCH_STORES_BY_BRAND_SUCCESS,
} from "../../../utils/constants";
import { getStoresByBrandId } from "../../../services/stores";

export const fetchStoresStarted = () => {
  return {
    type: FETCH_STORES_BY_BRAND_REQUEST,
  };
};

export const fetchedStoresSucceded = (stores: any[], brandId: string) => {
  return {
    type: FETCH_STORES_BY_BRAND_SUCCESS,
    payload: {
      stores,
      brandId,
    },
  };
};

export const fetchStoresFailed = (error?: any) => {
  return {
    type: FETCH_STORES_BY_BRAND_FAILURE,
    payload: error,
  };
};

export const getStoresForBrand = (brandId: string) => {
  return (dispatch) => {
    dispatch(fetchStoresStarted());
    return getStoresByBrandId(brandId)
      .then((stores) => {
        dispatch(fetchedStoresSucceded(stores, brandId));
      })
      .catch((e) => dispatch(fetchStoresFailed(e)));
  };
};
