import React from "react";
import { Link } from "react-router-dom";
import { isMobileOnly } from "react-device-detect";
import { useDispatch } from "react-redux";
import { selectBrandForDevicePreview } from "../../redux/actions/devices";
import { logEvent } from "../../analytics";
import { DID_SELECT_BRAND } from "../../utils/constants";
import { isUserPage } from "../../utils/common";
import { actionSelectBrandForHubUsersPreview } from "../../redux/actions/hubUsers";

const BrandCard = ({
  brandId,
  brandName,
  img,
}: {
  brandId: string;
  brandName: string;
  img?: string;
}) => {
  const dispatch = useDispatch();
  const logoImg =
    img ||
    `https://storiez-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/${brandId}/logo-light.png`;
  if (isMobileOnly) {
    return (
      <Link
        to={
          isUserPage()
            ? `/dashboard/hubusers/${brandId}`
            : `/dashboard/devices/${brandId}`
        }
      >
        <div className="devices_main-device-card">
          <img
            className="showcase-logo"
            src={logoImg}
            alt={`${brandName}'s logo`}
          />
        </div>
      </Link>
    );
  }
  return (
    <div
      className="devices_main-device-card"
      onClick={() => {
        logEvent(DID_SELECT_BRAND, DID_SELECT_BRAND, {
          brand: brandName,
        });
        dispatch(
          isUserPage()
            ? actionSelectBrandForHubUsersPreview(brandId)
            : selectBrandForDevicePreview(brandId)
        );
      }}
    >
      <img
        className="showcase-logo"
        src={logoImg}
        alt={`${brandName}'s logo`}
      />
      {brandName}
    </div>
  );
};

export default BrandCard;
