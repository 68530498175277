import { Hero } from "rbx";
import React from "react";
import Header from "../Common/Header";
import SearchInput from "../Common/SearchInput";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import BrandCard from "./BrandCard";
import BrandPreview from "./BrandPreview";
import { isUserPage } from "../../utils/common";
import BrandHubUserPreview from "../HubUsers/BrandHubUserPreview";
import { useDispatch, useSelector } from "react-redux";
import { IMainState } from "../../@types";
import { actionPublishers } from "../../redux/actions/Generic";
import { getPublishers } from "../../services/publisher";

const BrandDetail = () => {
  const dispatch = useDispatch();
  const publishers = useSelector(
    (state: IMainState) => state.generic?.publishers
  );
  const [searchInput, setSearchInput] = React.useState<string>("");

  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };
  React.useEffect(() => {
    if (!publishers) {
      getPublishers().then((publishers) =>
        dispatch(actionPublishers(publishers))
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Hero className="devices" size="fullheight">
        <Hero.Body>
          <Container fluid="sm">
            <Header />
            <Row>
              <Col xs={12} md={6}>
                <div className="devices_main">
                  <h1 className="devices_main-heading">Brands</h1>
                  <p className="devices_main-sub-heading">
                    {isUserPage() ? "Users" : "Devices"}
                  </p>
                  <SearchInput value={searchInput} onChange={handleSearch} />
                  <div className="brandDetail-list">
                    {publishers ? (
                      <Row>
                        {publishers
                          ?.filter((brand) =>
                            brand?.alias
                              .toLowerCase()
                              .includes(searchInput.toLowerCase())
                          )
                          .map((brand, index) => (
                            <Col key={index} xs={6} md={6} lg={4}>
                              <BrandCard
                                brandId={brand?.id}
                                brandName={brand?.alias}
                                img={brand?.avatar as string}
                              />
                            </Col>
                          ))}
                      </Row>
                    ) : (
                      <Spinner animation="border" size="sm" variant="light" />
                    )}
                  </div>
                </div>
              </Col>
              <Col xs={false} md={6} className="d-none d-md-block">
                {isUserPage() ? <BrandHubUserPreview /> : <BrandPreview />}
              </Col>
            </Row>
          </Container>
        </Hero.Body>
      </Hero>

      <style>
        {`
        .brandDetail-list{
          height: calc(100vh - 230px);
          overflow-y: auto;
          margin: 20px 0;
          overflow-x: hidden;
          text-align: center;
        }
      `}
      </style>
    </>
  );
};

export default BrandDetail;
