import React from "react";
import { IDevice } from "../../@types";
import { Command } from "../../services/device";
import { DeviceType } from "./AddDeviceModal";
import { Button, Form } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { sendDeviceCommand } from "../../redux/actions/devices";

function DeviceUninstallModal({
  device,
  onClose,
  command,
  type,
}: {
  device: IDevice;
  onClose: () => void;
  command: Command;
  type: DeviceType;
}) {
  const dispatch = useDispatch();
  const [packageName, setPackageName] = React.useState("");
  const onProceed = (e) => {
    e.preventDefault();
    const commandWithArg: Command = {
      type: command.type,
      argumments: {
        package_name: packageName,
      },
    };
    dispatch(sendDeviceCommand(device?.deviceId, type, commandWithArg));
    onClose();
  };
  return (
    <div className="device_uninstall_modal">
      <Form onSubmit={onProceed} className="device_uninstall">
        <Form.Label htmlFor="package_name">Enter package name</Form.Label>
        <Form.Control
          id="package_name"
          type="text"
          value={packageName}
          onChange={(e) => setPackageName(e.target.value)}
          placeholder="ex: com.example.android"
          required
        />
        <div className="d-flex gap-3">
          <Button id="Proceed" type="submit" variant="danger">
            Proceed
          </Button>
          <Button id="Cancel" onClick={onClose} type="button" variant="light">
            Cancel
          </Button>
        </div>
      </Form>
    </div>
  );
}

export default DeviceUninstallModal;
