import { Help } from "rbx";
import React from "react";
import { IRack } from "../../@types";
import TextInput from "../Common/TextInput";

const ConfigHeader = ({
  rack,
  setRack,
}: {
  rack: IRack;
  setRack: (rack: IRack) => void;
}) => {
  const [invalid, setInvalid] = React.useState(false);
  return (
    <>
      <div className="col-6 input-video">
        <span className="text-header">Rack Name</span>
        <TextInput
          placeholder="Rack Name"
          onChange={(event) => {
            setRack({ ...rack, name: event.target.value });
            setInvalid(false);
          }}
          value={rack?.name || ""}
          disabledEdit={!rack}
        />
        {invalid && <Help color="danger">invalid</Help>}
      </div>
      <div className="col-6 input-video">
        <span className="text-header">Description</span>

        <TextInput
          placeholder="Short Description"
          onChange={(event) => {
            setRack({ ...rack, description: event.target.value });
            setInvalid(false);
          }}
          value={rack?.description || ""}
          disabledEdit={!rack}
        />
        {invalid && <Help color="danger">invalid</Help>}
      </div>

      <p />
      <style>{`
      .text-header{
        display: flex;
        margin-left: 20px;
      }
        
      `}</style>
    </>
  );
};

export default ConfigHeader;
