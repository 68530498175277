import React from "react";
import { Spinner } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { IHubUser, IMainState, IStore } from "../../@types";
import { logEvent } from "../../analytics";
import {
  DID_SELECT_HUB_USER,
  STORE_ID,
  USER_ADMIN,
} from "../../utils/constants";
import SearchInput from "../Common/SearchInput";
import { getBrandNameById } from "../../config";
import { actionGetHubUsersForBrand } from "../../redux/actions/hubUsers";
import { HUB_USER_COMMANDS_MAP } from "../../services/hunUser";
const HubUsersList = ({
  brandId,
  setSelectedUser,
}: {
  brandId: string;
  setSelectedUser: (id: string) => void;
}) => {
  const stores: IStore[] = useSelector(
    (state: IMainState) => state?.stores?.stores
  )?.[brandId];
  const publishers = useSelector(
    (state: IMainState) => state?.generic?.publishers
  );
  const isFetching = useSelector(
    (state: IMainState) => state?.hubUsers?.fetching
  );
  const hubUsersForBrand =
    useSelector((state: IMainState) => state?.hubUsers?.hubUsers)?.[brandId] ||
    {};

  const hubUserCommandState = useSelector(
    (state: IMainState) => state?.hubUsers?.hubUserCommand
  );
  const lastExecutedCommand = hubUserCommandState?.executedCommand;
  const dispatch = useDispatch();

  const filter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate));

  const [searchInput, setSearchInput] = React.useState<string>("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  React.useEffect(() => {
    if (
      lastExecutedCommand?.type === HUB_USER_COMMANDS_MAP.REMOVE_HUB_USER.type
    ) {
      setSelectedUser(undefined);
      dispatch(actionGetHubUsersForBrand(brandId));
    }
  }, [brandId, dispatch, lastExecutedCommand, setSelectedUser]);

  React.useEffect(() => {
    setSearchInput("");
    console.log("brand Id~!#!-> ", brandId);
  }, [brandId]);
  return (
    <div>
      {!isFetching &&
        (Object.keys(hubUsersForBrand).length > 0 ? (
          <SearchInput value={searchInput} onChange={handleSearch} />
        ) : (
          <div className="devices_preview empty">
            <p className="devices_preview-info">
              User not available for {getBrandNameById(publishers, brandId)}
            </p>
          </div>
        ))}
      <div className="devicesByBrand_list">
        {isFetching ? (
          <Spinner animation="border" size="sm" />
        ) : (
          Object.values(
            filter(
              hubUsersForBrand,
              ([key, obj]) =>
                obj[0].user_name?.includes(searchInput.toLowerCase()) ||
                obj[0].id?.includes(searchInput.toLowerCase()) ||
                obj[0].alias
                  ?.toLowerCase()
                  .includes(searchInput.toLowerCase()) ||
                stores
                  ?.find(
                    (store) =>
                      store.id ===
                      obj[0]?.entitlements.find(
                        (obj) => obj.entitlementType === STORE_ID
                      )?.uuid
                  )
                  ?.name?.toLowerCase()
                  .includes(searchInput.toLowerCase())
            )
          )?.map((hubUser: IHubUser[], index) => (
            <div
              key={index}
              className="devicesByBrand_list-item"
              onClick={() => {
                logEvent(DID_SELECT_HUB_USER, DID_SELECT_HUB_USER, {
                  hubUser: hubUser[0]?.id,
                });
                setSelectedUser(hubUser[0]?.id);
              }}
            >
              <p className="devicesByBrand_list-item-name">
                {hubUser[0]?.user_name}
              </p>
              <p className="devicesByBrand_list-item-deviceType">
                {hubUser[0]?.alias}
              </p>
              <p>{hubUser[0]?.email}</p>
              <p>
                {
                  stores?.find(
                    (store) =>
                      store.id ===
                      hubUser[0]?.entitlements.find(
                        (obj) => obj.entitlementType === STORE_ID
                      )?.uuid
                  )?.name
                }
              </p>

              <span className="devicesByBrand_list-item-version">
                {hubUser[0]?.entitlements.find(
                  (obj) => obj.entitlementType === USER_ADMIN
                )?.uuid === undefined
                  ? hubUser[0]?.userType?.toUpperCase().replaceAll("_", " ")
                  : "ADMIN"}
              </span>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default HubUsersList;
