import { Hero } from "rbx";
import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../Common/Header";
import Button from "../Common/Button";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { allBrands } from "../../config";
import { useDispatch, useSelector } from "react-redux";
import { getDevicesForBrand } from "../../redux/actions/devices";
import { getStoresForBrand } from "../../redux/actions/stores";
import DevicesList from "./DevicesList";
import AddDeviceModal from "./AddDeviceModal";
import { IMainState } from "../../@types";
import { isMobileOnly } from "react-device-detect";
import { logEvent } from "../../analytics";
import { DID_CLICK_ADD_NEW_DEVICE } from "../../utils/constants";

const DeviceByBrand = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const brand = allBrands?.find((brand) => brand?.id === id);
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);
  const [selectedDevice, setSelectedDevice] = React.useState<string>(null);

  const isSubmitting = useSelector(
    (state: IMainState) => state?.devices?.isSubmitting
  );
  const newDeviceId = useSelector(
    (state: IMainState) => state?.devices?.newDeviceId
  );

  const onBackCb = () => {
    setSelectedDevice(null);
  };

  React.useEffect(() => {
    if (brand) {
      dispatch(getDevicesForBrand(brand?.id));
      dispatch(getStoresForBrand(brand?.id));
    }
  }, [brand, dispatch]);

  React.useEffect(() => {
    if (!isSubmitting && newDeviceId && brand) {
      dispatch(getDevicesForBrand(brand?.id));
    }
  }, [isSubmitting, newDeviceId, brand, dispatch]);

  if (!brand || !isMobileOnly) {
    return <Redirect to="/dashboard/devices" />;
  }

  return (
    <>
      <Hero className="devices devicesByBrand" size="fullheight">
        <Hero.Body>
          <Container fluid="sm">
            <Header onBack={selectedDevice ? onBackCb : undefined} />
            <div className="devices_main">
              <h1 className="devices_main-heading">{brand?.name}</h1>
              {!selectedDevice && (
                <Button
                  id="createNewDevice"
                  text="+  Add Device"
                  onClick={() => {
                    logEvent(DID_CLICK_ADD_NEW_DEVICE);
                    setShowAddForm(true);
                  }}
                />
              )}

              <DevicesList
                brandId={brand?.id}
                selectedDevice={selectedDevice}
                setSelectedDevice={setSelectedDevice}
              />
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      <AddDeviceModal
        brandId={id}
        isOpen={showAddForm}
        onClose={() => setShowAddForm(false)}
      />
    </>
  );
};

export default DeviceByBrand;
