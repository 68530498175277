import { DeviceType, IAddDeviceFormState } from "../../components/DevicesByBrand/AddDeviceModal";
import { prodBaseUrl } from "../../config";
import { generateV4UUID } from "../../utils/identityGenerator";
import { apigClient } from "../aws/apigClient";

export interface Command {
  type: string;
  argumments: { [key: string]: string | number | any } | string[];
}

export interface IDeviceCommandArgs {
  version: string;
  download_apk_url: string;
}
export interface IDeviceCommand {
  requestId: string;
  action: string;
  deviceId: string;
  type?: DeviceType;
  command: string;
  args?: { [key: string]: string } | string[];
}

export const DEVICE_COMMANDS_MAP = {
  LENSE_VERSION_UPDATE: {
    type: "LENSE_VERSION_UPDATE",
    argumments: ["download_apk_url", "version"],
  },
  LENSE_SCREEN_VERSION_UPDATE: {
    type: "LENSE_SCREEN_VERSION_UPDATE",
    argumments: ["download_apk_url", "version"],
  },
  LENSE_ADMIN_VERSION_UPDATE: {
    type: "LENSE_ADMIN_VERSION_UPDATE",
    argumments: ["download_apk_url", "version"],
  },
  SSP_VERSION_UPDATE: {
    type: "SSP_VERSION_UPDATE",
    argumments: ["download_apk_url", "version"],
  },
  DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE: {
    type: "DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE",
    argumments: ["download_apk_url", "version"],
  },
  CLEAR_IMAGE_CACHE: {
    type: "CLEAR_IMAGE_CACHE",
    argumments: [],
  },
  UPDATE_ALL_DATA: {
    type: "UPDATE_ALL_DATA",
    argumments: [],
  },
  UPDATE_FACETS: {
    type: "UPDATE_FACETS",
    argumments: [],
  },
  REMOVE_DEVICE: {
    type: "REMOVE_DEVICE",
    argumments: [],
  },
  REBOOT_SYSTEM: {
    type: "REBOOT_SYSTEM",
    argumments: [],
  },
  LOGS: {
    type: "LOGS",
    argumments: [],
  },
  UNINSTALL: {
    type: "UNINSTALL_APP",
    argumments: [],
  },
  GRAFANA: {
    type: "GRAFANA",
    argumments: [],
  },
  SCHEDULE_RESTART: {
    type: "UPDATE_REBOOT_TIME",
    argumments: [],
  },

  UPDATE_DENSITY: {
    type: "UPDATE_DENSITY",
    argumments: [],
  },
  UPDATE_PRODUCTS: {
    type: "UPDATE_PRODUCTS",
    argumments: [],
  },
  LENSE_CONFIGURATION_UPDATE: {
    type: "LENSE_CONFIGURATION_UPDATE",
    argumments: [],
  },
  MOVE_DEVICE: {
    type: "MOVE_DEVICE",
    argumments: [],
  },
};

export const addDeviceService = async (formData: IAddDeviceFormState): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const body = {
    deviceId: formData?.deviceId,
    storeId: formData?.storeId,
    name: formData?.name,
    applicationName: formData?.applicationName,
  };
  const result = await client.invokeApi({}, "/registerAndroidDevice/v1", "POST", {}, body);
  return result.data;
};

export const removeDeviceService = async (deviceId: string, storeId: string): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const body = {
    deviceId: deviceId,
    storeId: storeId,
  };
  const result = await client.invokeApi({}, "/unregisterAndroidDevice/v1", "POST", {}, body);
  return result.data;
};

export const getDevicesService = async (brandId: string): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const path = `/applications/v1`;
  const result = await client.invokeApi({}, path, "GET");
  return result.data;
};

export const getDevicesByBrandService = async (brandId: string): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const path = `/storeDevices/byBrandId/v1`;
  const additionalParams = {
    queryParams: {
      brandId: brandId,
    },
  };
  const result = await client.invokeApi({}, path, "GET", additionalParams);
  return result.data;
};

export const sendSSPCommandService = async (deviceId: string, command: Command): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const body: IDeviceCommand = {
    requestId: generateV4UUID(),
    action: "sendcommand",
    deviceId,
    command: command.type,
    type: DeviceType.Bolt,
    args:
      command.argumments && Object.keys(command.argumments).length > 0
        ? {
            ...command.argumments,
          }
        : {},
  };
  console.info(body);
  const path = "/commands/v2";
  const result = await client.invokeApi({}, path, "POST", {}, body);
  return result.data;
};

export const sendLenseCommandService = async (deviceId: string, command: Command, type?: DeviceType): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  let deviceType = DeviceType.Bolt;
  const sendToLense = [DEVICE_COMMANDS_MAP.CLEAR_IMAGE_CACHE, DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA, DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS]
    .map((c) => c.type)
    .includes(command.type);
  if (sendToLense) {
    deviceType = DeviceType.Lense;
  }
  const body: IDeviceCommand = {
    requestId: generateV4UUID(),
    action: "sendcommand",
    deviceId,
    command: command.type,
    type: type || deviceType,
    args:
      command.argumments && Object.keys(command.argumments).length > 0
        ? {
            ...command.argumments,
          }
        : {},
  };
  console.info(body);
  const path = "/commands/v2";
  const result = await client.invokeApi({}, path, "POST", {}, body);
  return result.data;
};

export const sendLenseScreenCommandService = async (deviceId: string, command: Command, type?: DeviceType): Promise<any[]> => {
  const client = await apigClient(prodBaseUrl);
  const body: IDeviceCommand = {
    requestId: generateV4UUID(),
    action: "sendcommand",
    deviceId,
    command: command.type,
    type: type || DeviceType.Bolt,
    args:
      command.argumments && Object.keys(command.argumments).length > 0
        ? {
            ...command.argumments,
          }
        : {},
  };
  console.info(body);
  const path = "/commands/v2";
  const result = await client.invokeApi({}, path, "POST", {}, body);
  return result.data;
};

export const moveDeviceToBrandService = async (payload: { deviceId: string; storeId: string }): Promise<any> => {
  const additionalParams = {
    queryParams: {
      deviceId: payload.deviceId,
      storeId: payload.storeId,
    },
  };
  const client = await apigClient(prodBaseUrl);
  const path = `/moveDevice/v1`;
  const result = await client.invokeApi({}, path, "GET", additionalParams);
  return result.data;
};
