import * as H from "history";
import {
  LOGIN_FAILED,
  LOGIN_START,
  LOGIN_SUCCESSFUL,
} from "../../../utils/constants";
import {
  cacheCredentials,
  checkChallengeAmplify,
  login,
} from "../../../services/login";

export const loginStarted = () => {
  return {
    type: LOGIN_START,
  };
};

export const loginSuccessful = () => {
  return {
    type: LOGIN_SUCCESSFUL,
  };
};

export const loginFailed = (error?: any) => {
  return {
    type: LOGIN_FAILED,
    payload: error,
  };
};

export const actionLoginAsync = (
  username: string,
  password: string,
  history: H.History<any>
) => {
  return (dispatch) => {
    dispatch(loginStarted());
    return login(username, password)
      .then(checkChallengeAmplify)
      .then(cacheCredentials)
      .then((credentials) => {
        dispatch(loginSuccessful());
        history.push("/dashboard");
      })
      .catch((e) => dispatch(loginFailed(e)));
  };
};
