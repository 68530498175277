import { IStore } from "../../@types";
import { apigClient } from "../aws/apigClient";

export const getStoresByBrandId = async (
  brandId: string
): Promise<IStore[]> => {
  const client = await apigClient();
  const path = `/stores/v2/byBrandId/${brandId}`;
  const method = "GET";
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
};

export const getAllStores = async (): Promise<IStore[]> => {
  const client = await apigClient();
  const path = `/stores/v1/all`;
  const method = "GET";
  const result = await client.invokeApi({}, path, method, {});
  return result.data;
};
