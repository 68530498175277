import { isPlainObject } from "lodash";
import { PageView } from "../@types";
import { sendCustomAnalytics } from "../services/customAnalytics";
import { ANALYTICS_SOURCE, DID_VIEW_PAGE } from "../utils/constants";
import { generateV4UUID, getIdentityId } from "../utils/identityGenerator";

export type LogEvent = (
  category?: string,
  action?: string,
  payload?: any
) => void;

export interface AnalyticEvent {
  type: string;
  payload: any;
  event_timestamp: number;
  sourceSystem: string;
  identityId: string;
}

let events: AnalyticEvent[] = [];

export const logPageView = (pageView: PageView) => {
  console.log(`Logging pageview for ${window.location.pathname}`, pageView);
  logCustomEvent(DID_VIEW_PAGE, {
    url: window.location.href,
    pageType: pageView,
  });
};

export const logEvent: LogEvent = (
  category = "",
  action = "",
  payload = null
) => {
  if (category) {
    const defaultPayload = {
      action: action || category,
    };
    if (payload && isPlainObject(payload)) {
      logCustomEvent(category, {
        ...defaultPayload,
        ...payload,
      });
    } else {
      logCustomEvent(category, defaultPayload);
    }
  }
};

export const addEventToQueue = (event: AnalyticEvent) => {
  events = [...events, event];
  if (events.length >= 5) {
    flush();
  }
};

const logCustomEvent = (category: string, payload: any) => {
  const event = getAnalyticEvent(category, payload);
  if (getIdentityId()) {
    addEventToQueue(event);
  } else {
    console.log("identityId is null in logEvent call!");
    const eventWithIdentity = { ...event, identityId: generateV4UUID() };
    addEventToQueue(eventWithIdentity);
  }
};

export const getAnalyticEvent = (category: string, payload: any) => {
  const event: AnalyticEvent = {
    type: category,
    payload: {
      ...payload,
      url: window.location.href,
      userAgent: navigator.userAgent,
    },
    event_timestamp: new Date().getTime(),
    identityId: getIdentityId(),
    sourceSystem: ANALYTICS_SOURCE,
  };
  return event;
};

const getHashPathFromPageType = (page: PageView, brandId: string) => {
  switch (page) {
    case PageView.DASHBOARD:
      return "dashboard";
    case PageView.DEVICES:
      return "dashboard/devices";
    case PageView.DEVICE_DETAILS_ON_MOBILE:
      return `dashboard/devices/${brandId}`;
    case PageView.USERS:
      return "dashboard/hubusers";
    case PageView.USERS_DETAILS_ON_MOBILE:
      return `dashboard/hubusers/${brandId}`;

    default:
      return null;
  }
};

export const getPageTypeFromHashPath = (path: string): PageView => {
  const pathSplitted = path.split("/");
  const brandId = pathSplitted[pathSplitted.length - 1];
  const page = Object.keys(PageView).find(
    (key) => getHashPathFromPageType(PageView[key], brandId) === path
  );
  if (page) return PageView[page];

  return PageView.DASHBOARD;
};

export const flush = () => {
  if (events.length > 0) {
    sendCustomAnalytics([...events]);
  }
  events = [];
};

window.addEventListener("beforeunload", () => {
  flush();
});
