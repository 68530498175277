import { Container, Field, Control, Column, Help } from "rbx";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IMainState, IStore } from "../../@types";
import { isValidName } from "../../utils/common";
import TextInput from "../Common/TextInput";
import SelectInput from "../Common/SelectInput";
import Button from "../Common/Button";
import Modal from "../Common/Modal";
import { addNewDevice, getDevicesForBrand } from "../../redux/actions/devices";
import { logEvent } from "../../analytics";
import { DID_SUBMIT_NEW_DEVICE } from "../../utils/constants";
import { toast } from "react-toastify";
interface IAddDeviceFormPristine {
  deviceId: boolean;
  storeId: boolean;
  name: boolean;
}

export interface IAddDeviceFormState {
  deviceId: string;
  storeId: string;
  name: string;
  applicationName: string;
}

export enum DeviceType {
  Bolt = "Bolt",
  SSP = "SSP",
  Lense = "Lense",
  DiamondLight = "DiamondLight",
  LenseScreen = "LenseScreen",
}
const UNKNOWN_CITY = "UNKNOWN_CITY";

const DEVICE_TYPE_OPTIONS = [
  {
    value: DeviceType.SSP,
    text: DeviceType.SSP,
  },
  {
    value: DeviceType.Lense,
    text: DeviceType.Lense,
  },
  {
    value: DeviceType.LenseScreen,
    text: DeviceType.LenseScreen,
  },
];
const AddDeviceModal = ({
  brandId,
  isOpen,
  onClose,
}: {
  brandId: string;
  isOpen: boolean;
  onClose: () => void;
}) => {
  const dispatch = useDispatch();
  const stores: IStore[] = useSelector(
    (state: IMainState) => state?.stores?.stores
  )?.[brandId];
  const isSubmitting = useSelector(
    (state: IMainState) => state?.devices?.isSubmitting
  );
  const newDeviceId = useSelector(
    (state: IMainState) => state?.devices?.newDeviceId
  );
  const deviceError = useSelector((state: IMainState) => state?.devices?.error);
  const [pristine, setPristine] = React.useState<IAddDeviceFormPristine>({
    deviceId: true,
    storeId: true,
    name: true,
  });

  const [formState, setFormState] = React.useState<IAddDeviceFormState>({
    deviceId: "",
    storeId: "",
    name: "",
    applicationName: DeviceType.SSP,
  });

  const [selectedCity, setSelectedCity] = React.useState<string>("All");

  const invalidDeviceId =
    !pristine.deviceId && !isValidName(formState?.deviceId);
  const invalidStoreId = !pristine.storeId && !isValidName(formState?.storeId);
  const invalidName = !pristine.name && !isValidName(formState?.name);

  const handleOnChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFieldName = e?.target?.name;
    const inputValue = e?.target?.value;
    setPristine({
      ...pristine,
      [inputFieldName]: false,
    });
    setFormState({
      ...formState,
      [inputFieldName]: inputValue,
    });
  };

  const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const inputValue = e?.target?.value;
    const inputFieldName = e?.target?.name;
    setFormState({
      ...formState,
      [inputFieldName]: inputValue,
    });
  };

  const resetForm = () => {
    setPristine({
      deviceId: true,
      storeId: true,
      name: true,
    });
    setFormState((state) =>
      Object.assign(
        {},
        {
          ...state,
          deviceId: "",
          name: "",
          applicationName: DeviceType.SSP,
        }
      )
    );
    setSelectedCity("All");
  };

  const removePristine = () => {
    setPristine({
      deviceId: false,
      storeId: false,
      name: false,
    });
  };

  const handleClose = React.useCallback(() => {
    resetForm();
    onClose();
  }, [onClose]);

  const handleSave = () => {
    if (isSubmitting) {
      return;
    }

    removePristine();

    if (
      !isValidName(formState?.deviceId) ||
      !isValidName(formState?.storeId) ||
      !isValidName(formState?.name)
    ) {
      return;
    }

    logEvent(DID_SUBMIT_NEW_DEVICE, DID_SUBMIT_NEW_DEVICE, {
      ...formState,
    });
    dispatch(addNewDevice(formState));
  };

  const storeSelectOptions = React.useMemo(() => {
    return stores
      ?.filter((store) => {
        if (selectedCity === "All") return true;
        if (selectedCity === UNKNOWN_CITY) return !store.city;
        return store.city === selectedCity;
      })
      .map((store) => Object.assign({ value: store?.id, text: store?.name }));
  }, [stores, selectedCity]);

  React.useEffect(() => {
    setFormState((formState) => ({
      ...formState,
      storeId: storeSelectOptions?.[0]?.value,
    }));
  }, [storeSelectOptions]);

  const citySelectOptions = React.useMemo(() => {
    const cities = ["All"];
    stores?.forEach((store) => {
      const storeCity = store?.city || UNKNOWN_CITY;
      if (!cities.includes(storeCity)) {
        cities.push(storeCity);
      }
    });
    return cities
      .sort((a, b) => (b === UNKNOWN_CITY ? -1 : a.localeCompare(b)))
      .map((city) => {
        const value = city;
        const text = city === UNKNOWN_CITY ? "Unknown" : city;
        return Object.assign({ value, text });
      });
  }, [stores]);

  const handleOnSelectCity = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const inputValue = e?.target?.value;
    setSelectedCity(inputValue);
  };

  React.useEffect(() => {
    if (stores?.length > 0) {
      setFormState((state) =>
        Object.assign({}, { ...state, storeId: stores?.[0]?.id })
      );
    }
  }, [stores]);

  React.useEffect(() => {
    if (!isSubmitting && newDeviceId) {
      handleClose();
      dispatch(getDevicesForBrand(brandId));
    }
  }, [isSubmitting, newDeviceId, handleClose, dispatch, brandId]);

  React.useEffect(() => {
    if (newDeviceId) {
      toast.success(
        newDeviceId?.["message"] ? newDeviceId?.["message"] : "Device added",
        {
          autoClose: 5000,
        }
      );
    }
  }, [newDeviceId]);

  React.useEffect(() => {
    if (deviceError)
      toast.error(
        deviceError?.["message"]
          ? deviceError?.["message"]
          : "Unable to add Device",
        {
          autoClose: 5000,
        }
      );
  }, [deviceError]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title="Add New Device"
      showClose
    >
      <Container id="addDeviceForm" className="add-device-form">
        <Column.Group className="add-device-form__column-group">
          <Column size="half">
            <Field>
              <label className="input-label">Device ID</label>
              <Control>
                <TextInput
                  id="deviceId"
                  name="deviceId"
                  placeholder="Device id"
                  value={formState?.deviceId}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidDeviceId && <Help color="danger">Required</Help>}
            </Field>
          </Column>
          <Column size="one-fifth">
            <Field>
              <label className="input-label">City</label>
              <Control>
                <SelectInput
                  name="city"
                  value={selectedCity}
                  onChange={handleOnSelectCity}
                  options={citySelectOptions}
                />
              </Control>
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="input-label">Store</label>
              <Control>
                <SelectInput
                  name="storeId"
                  value={formState?.storeId || ""}
                  onChange={handleOnSelectChange}
                  options={storeSelectOptions}
                />
              </Control>
              {invalidStoreId && <Help color="danger">Required</Help>}
            </Field>
          </Column>
        </Column.Group>
        <Column.Group className="add-device-form__column-group">
          <Column>
            <Field>
              <label className="input-label">Title</label>
              <Control>
                <TextInput
                  id="name"
                  name="name"
                  placeholder="Device name"
                  value={formState?.name}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidName && <Help color="danger">Required</Help>}
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="input-label">Type of Device</label>
              <Control>
                <SelectInput
                  name="applicationName"
                  value={formState?.applicationName || ""}
                  onChange={handleOnSelectChange}
                  options={DEVICE_TYPE_OPTIONS}
                />
              </Control>
            </Field>
          </Column>
        </Column.Group>
        <div className="add-device-form_footer">
          <Button
            id="createNewDevice"
            text="Submit"
            className="submit-add-new-device"
            isLoading={isSubmitting}
            onClick={() => handleSave()}
          />
        </div>
      </Container>
    </Modal>
  );
};

export default AddDeviceModal;
