import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMainState } from "../../@types";
import { getBrandNameById } from "../../config";
import { getDevicesForBrand, resetDevice } from "../../redux/actions/devices";
import { getStoresForBrand } from "../../redux/actions/stores";
import AddDeviceModal from "../DevicesByBrand/AddDeviceModal";
import DevicesList from "../DevicesByBrand/DevicesList";
import Button from "../Common/Button";
import { IconBack } from "../Common/Icons";
import { logEvent } from "../../analytics";
import {
  DID_CLICK_ADD_DEVICE,
  DID_CLOSE_SELECT_BRAND,
} from "../../utils/constants";

const BrandPreview = () => {
  const dispatch = useDispatch();
  const brandId = useSelector((state: IMainState) => state?.devices?.brandId);
  const publishers = useSelector((state: IMainState) => state?.generic?.publishers);
  const [selectedDevice, setSelectedDevice] = React.useState<string>(null);
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);

  React.useEffect(() => {
    if (brandId) {
      setSelectedDevice(null);
      dispatch(getDevicesForBrand(brandId));
      dispatch(getStoresForBrand(brandId));
    }
  }, [brandId, dispatch]);

  if (!brandId) {
    return (
      <div className="devices_preview empty">
        <p className="devices_preview-info">Please select a brand</p>
      </div>
    );
  }

  return (
    <>
      <div className="devices_preview devices_main">
        <h1 className="devices_main-heading">{getBrandNameById(publishers,brandId)}</h1>
        {!selectedDevice && (
          <Button
            id="createNewDevice"
            text="+  Add Device"
            onClick={() => {
              logEvent(DID_CLICK_ADD_DEVICE);
              dispatch(resetDevice());
              setShowAddForm(true);
            }}
          />
        )}
        {selectedDevice && (
          <div
            className="back"
            onClick={() => {
              logEvent(DID_CLOSE_SELECT_BRAND);
              setSelectedDevice(null);
            }}
          >
            <IconBack />
          </div>
        )}
        <DevicesList
          brandId={brandId}
          selectedDevice={selectedDevice}
          setSelectedDevice={setSelectedDevice}
        />
      </div>
      <AddDeviceModal
        brandId={brandId}
        isOpen={showAddForm}
        onClose={() => setShowAddForm(false)}
      />
    </>
  );
};

export default BrandPreview;
