import React from "react";
import { IDevice, IStore } from "../../@types";
import Button from "../Common/Button";
import SearchInput from "../Common/SearchInput";
import { OverlayTrigger, Popover, Spinner } from "react-bootstrap";
import { moveDeviceToBrandService } from "../../services/device";
import { getAllStores } from "../../services/stores";
import { toast } from "react-toastify";

// function BrandImage({ brand }: { brand: IPublisher }) {
//   const [err, setErr] = React.useState(false);
//   const imgSrc = React.useMemo(() => {
//     if (err) {
//       return "/empty.jpg";
//     }
//     return (
//       (brand.avatar as string) ||
//       `https://storiez-panos.s3-ap-southeast-1.amazonaws.com/advisor_hub/${brand.id}/logo-light.png`
//     );
//   }, [brand, err]);

//   return <img src={imgSrc} alt={brand.alias} onError={() => setErr(true)} />;
// }

function DeviceUpdateStore({
  device,
  onClose,
}: {
  device: IDevice;
  onClose: () => void;
}) {
  const [searchInput, setSearchInput] = React.useState<string>("");
  const [selectedStore, setSelectedStore] = React.useState<IStore | null>(null);
  const [moving, setMoving] = React.useState(false);
  const [loadingStores, setLoadingStores] = React.useState(false);
  const [stores, setStores] = React.useState<IStore[]>([]);

  React.useEffect(() => {
    setLoadingStores(true);
    getAllStores()
      .then((stores) => setStores(stores))
      .catch(console.error)
      .finally(() => setLoadingStores(false));
  }, []);

  const onMove = (storeId: string) => {
    setMoving(true);
    const payload = {
      deviceId: device.deviceId,
      storeId,
    };
    moveDeviceToBrandService(payload)
      .then(() => {
        toast.success("Move device to new store successfully");
        onClose();
      })
      .catch((error) => {
        console.log(error);
        toast.error("Move device to new store failed");
      })
      .finally(() => {
        setMoving(false);
      });
  };

  const filteredStores = React.useMemo(() => {
    if (!stores) return [];
    return stores
      .filter((store) => {
        return (
          store.id !== device.storeId &&
          store.name.toLowerCase().includes(searchInput.toLowerCase())
        );
      })
      ?.sort((a, b) => a.name.trim().localeCompare(b.name));
  }, [stores, searchInput, device.storeId]);

  const popover = (
    <Popover id="popover-move-device">
      <Popover.Title as="h3">
        Move device to <strong>{selectedStore?.name}</strong>?
      </Popover.Title>
      <Popover.Content>
        <Button
          text="Confirm"
          className="no-hover"
          onClick={() => onMove(selectedStore?.id as string)}
          isLoading={moving}
        />
        <Button
          className="no-hover"
          text="Cancel"
          onClick={() => setSelectedStore(null)}
        />
      </Popover.Content>
    </Popover>
  );

  return (
    <div className="select-brand-list">
      <SearchInput
        value={searchInput}
        onChange={(e) => setSearchInput(e.target.value)}
      />
      {loadingStores && (
        <Spinner animation="border" size="sm" variant="light" />
      )}

      {!loadingStores && (
        <ul className="list-group hover">
          {filteredStores.map((store, index) => {
            return (
              <li
                key={index}
                className="select-brand-list-item list-group-item"
              >
                <span className="brand-detail">
                  <span>{store.name}</span>
                </span>
                <OverlayTrigger
                  trigger={["click"]}
                  placement="left"
                  overlay={popover}
                  show={
                    selectedStore?.id === store.id &&
                    selectedStore?.name === store.name
                  }
                >
                  <Button
                    text="Move"
                    onClick={() => setSelectedStore(store)}
                    className="btn-move no-hover"
                    disabled={moving}
                  />
                </OverlayTrigger>
              </li>
            );
          })}
        </ul>
      )}
      <Button text="Back" className="no-hover" onClick={onClose} />
    </div>
  );
}

export default DeviceUpdateStore;
