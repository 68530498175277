import { Auth } from "aws-amplify";
import { IDENTITY_ID } from "../../utils/constants";

export const login = async (username: string, password: string) => {
  const loginResponse = await Auth.signIn(username, password);
  return loginResponse;
};

export const checkChallengeAmplify = (loginResponse: any) => {
  if (loginResponse?.challengeName)
    console.log("Error in challenge", loginResponse?.challengeName);
  return loginResponse;
};

export const hasValidSession = async (): Promise<boolean> => {
  try {
    const session = await Auth.currentSession();
    return session.isValid();
  } catch (error) {
    return false;
  }
};

export const cacheCredentials = async () => {
  try {
    const credentials = await Auth.currentCredentials();
    localStorage.setItem(IDENTITY_ID, credentials.identityId);
    sessionStorage.setItem("expiry", (credentials as any)?.expiration);
    return credentials;
  } catch (e) {
    console.error(e);
  }
};

export const signOut = () => {
  return Auth.signOut();
};

export const hasValidCredentials = (): boolean => {
  const expiry = sessionStorage.getItem("expiry");
  return expiry !== null && new Date(expiry) > new Date();
};
export const refreshCredentials = async () => {
  const session = await Auth.currentSession();
  const user = await Auth.currentAuthenticatedUser();
  user.refreshSession(session.getRefreshToken(), (e) => {
    console.error(e);
  });
};
export const checkValidSession = () => {
  hasValidSession();
  if (!hasValidCredentials()) {
    refreshCredentials();
    cacheCredentials();
  }
};
