import defaultState from "../initialState";
import { IAction, IMainState } from "../../../@types";
import { GET_USER_OBJECT_SUCCESS } from "../../../utils/constants";

const userReducer = (
  state: IMainState = defaultState,
  action: IAction<any>
) => {
  switch (action.type) {
    case GET_USER_OBJECT_SUCCESS: {
      return { ...state, user: action?.payload };
    }
    default:
      return state;
  }
};

export default userReducer;
