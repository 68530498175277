import {
  FAILED_FETCH_DEVICES_BY_BRAND,
  FETCHING_DEVICES_BY_BRAND,
  SUCCESSFUL_FETCH_DEVICES_BY_BRAND,
  ADD_NEW_DEVICE_REQUEST,
  ADD_NEW_DEVICE_SUCCESS,
  ADD_NEW_DEVICE_FAILURE,
  SEND_DEVICE_COMMAND_REQUEST,
  SEND_DEVICE_COMMAND_SUCCESS,
  SEND_DEVICE_COMMAND_FAILURE,
  SAVE_VERSIONS_TO_REDUX,
  RESET_DEVICE_COMMAND_STATE,
  SELECT_BRAND_FOR_DEVICES_PREVIEW,
  RESET_NEW_DEVICE_REQUEST,
  STATUS_OK,
} from "../../../utils/constants";
import {
  DeviceType,
  IAddDeviceFormState,
} from "../../../components/DevicesByBrand/AddDeviceModal";
import {
  addDeviceService,
  Command,
  sendSSPCommandService,
  sendLenseCommandService,
  getDevicesByBrandService,
  removeDeviceService,
  sendLenseScreenCommandService,
} from "../../../services/device";
import {
  getSSPVersions,
  getLenseBoltVersions,
  getLenseBarVersions,
  extractVersions,
  getDiamondLightVersions,
  extractDLVersions,
  getLenseScreenVersions,
} from "../../../services/versions";
import { IGroupedDeviceMap, IVersionsMap } from "../../../@types";
import { groupBy } from "lodash";

export const addDeviceRequest = () => {
  return {
    type: ADD_NEW_DEVICE_REQUEST,
  };
};

export const addDeviceSuccess = (newDeviceId) => {
  return {
    type: ADD_NEW_DEVICE_SUCCESS,
    payload: newDeviceId,
  };
};

export const resetDevice = () => {
  return {
    type: RESET_NEW_DEVICE_REQUEST,
    payload: undefined,
  };
};

export const addDeviceFailed = (error?: any) => {
  return {
    type: ADD_NEW_DEVICE_FAILURE,
    payload: error,
  };
};

export const addNewDevice = (formData: IAddDeviceFormState) => {
  return (dispatch) => {
    dispatch(addDeviceRequest());
    return addDeviceService(formData)
      .then((newDevice) => {
        if (newDevice["status"] === STATUS_OK) {
          dispatch(addDeviceSuccess(newDevice));
        } else {
          dispatch(addDeviceFailed(newDevice));
        }
      })
      .catch((e) => dispatch(addDeviceFailed(e)));
  };
};

export const devicesFetchStarted = () => {
  return {
    type: FETCHING_DEVICES_BY_BRAND,
  };
};

export const fetchedDevices = (devices: IGroupedDeviceMap, brandId: string) => {
  return {
    type: SUCCESSFUL_FETCH_DEVICES_BY_BRAND,
    payload: {
      devices,
      brandId,
    },
  };
};

export const fetchDevicesFailed = (error?: any) => {
  return {
    type: FAILED_FETCH_DEVICES_BY_BRAND,
    payload: error,
  };
};

export const getDevicesForBrand = (brandId: string) => {
  return (dispatch) => {
    dispatch(devicesFetchStarted());
    return getDevicesByBrandService(brandId)
      .then((devices) => {
        const devicesGroupedById = groupBy(
          devices.filter((device) => device?.deviceId),
          "deviceId"
        );
        dispatch(fetchedDevices(devicesGroupedById, brandId));
      })
      .catch((e) => dispatch(fetchDevicesFailed(e)));
  };
};

export const sendDeviceCommandRequest = () => {
  return {
    type: SEND_DEVICE_COMMAND_REQUEST,
  };
};

export const sendDeviceCommandSuccess = (payload: Command) => {
  return {
    type: SEND_DEVICE_COMMAND_SUCCESS,
    payload,
  };
};

export const sendDeviceCommandFailed = (error?: any) => {
  return {
    type: SEND_DEVICE_COMMAND_FAILURE,
    payload: error,
  };
};

export const resetDeviceCommandState = () => {
  return {
    type: RESET_DEVICE_COMMAND_STATE,
  };
};
const getSendCommandService = (type: DeviceType) => {
  switch (type) {
    case DeviceType.Lense:
      return sendLenseCommandService;
    case DeviceType.LenseScreen:
      return sendLenseScreenCommandService;
    default:
      return sendSSPCommandService;
  }
};
export const sendDeviceCommand = (
  deviceId: string,
  type: DeviceType,
  command: Command
) => {
  const sendCommandService = getSendCommandService(type);

  return (dispatch) => {
    dispatch(sendDeviceCommandRequest());
    return sendCommandService(deviceId, command)
      .then((data) => {
        dispatch(sendDeviceCommandSuccess(command));
      })
      .catch((e) => dispatch(sendDeviceCommandFailed(e)));
  };
};

export const removeDeviceCommand = (
  deviceId: string,
  storeId: string,
  command: Command
) => {
  return (dispatch) => {
    dispatch(sendDeviceCommandRequest());
    return removeDeviceService(deviceId, storeId)
      .then((data) => {
        dispatch(sendDeviceCommandSuccess(command));
      })
      .catch((e) => dispatch(sendDeviceCommandFailed(e)));
  };
};

export const saveVersionsToRedux = (payload?: IVersionsMap) => {
  return {
    type: SAVE_VERSIONS_TO_REDUX,
    payload,
  };
};

export const getVersions = () => {
  return async (dispatch) => {
    try {
      const pending = [
        getLenseBarVersions(),
        getLenseBoltVersions(),
        getSSPVersions(),
        getDiamondLightVersions(),
        getLenseScreenVersions(),
      ];
      const result = await Promise.all(pending);
      const versionsMap: IVersionsMap = {
        Lense: extractVersions(result[0], "installation/lense-bar/", [
          "LenseBar_",
        ]),
        Bolt: extractVersions(result[1], "installation/lense-admin/", [
          "LenseAdmin_",
          "Bolt_",
        ]),
        SSP: extractVersions(result[2], "installation/streaming-studio/", [
          "ssp-android_",
        ]),
        DiamondLight: extractDLVersions(
          result[3],
          "installation/diamond-light-controller/",
          ["DiamondLightController_"]
        ),
        LenseScreen: extractVersions(result[4], "installation/lense-screen/", [
          "LenseScreen_",
        ]),
      };
      dispatch(saveVersionsToRedux(versionsMap));
    } catch (e) {
      console.error(e);
    }
  };
};

export const selectBrandForDevicePreview = (brandID?: string) => {
  return {
    type: SELECT_BRAND_FOR_DEVICES_PREVIEW,
    payload: brandID,
  };
};
