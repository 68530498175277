import React from "react";
import { Spinner } from "react-bootstrap";

const Button = ({
  id,
  isLoading,
  text,
  className,
  disabled,
  onClick,
  border,
}: {
  id?: string;
  isLoading?: boolean;
  text: string;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  border?: boolean;
}) => {
  return (
    <button
      id={id}
      className={`btn common-button ${className} ${isLoading && "is-loading"} ${
        border && "border-variant"
      }`}
      disabled={disabled}
      onClick={onClick}
    >
      {isLoading ? <Spinner animation="border" size="sm" /> : text}
    </button>
  );
};

export default Button;
