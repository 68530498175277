import {
  Column,
  Container,
  Hero,
  Field,
  Control,
  Icon,
  Notification,
  Delete,
} from "rbx";
import { IconUser, IconPassword } from "../Common/Icons";
import React from "react";
import Button from "../Common/Button";
import { useDispatch, useSelector } from "react-redux";
import { IMainState } from "../../@types";
import { actionLoginAsync } from "../../redux/actions/login";
import { useHistory } from "react-router-dom";

function Login() {
  const dispatch = useDispatch();
  const history = useHistory();
  const loginState = useSelector(({ login }: IMainState) => login);
  const loginInProgress = loginState?.inProgress;

  const [username, setUsername] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [error, setError] = React.useState(undefined as any);

  React.useEffect(() => {
    setError(loginState?.error);
  }, [loginState?.error]);

  const handleLogin = (e?: React.KeyboardEvent<HTMLInputElement>) => {
    if ((!e || e.key === "Enter") && username.trim() && password) {
      dispatch(actionLoginAsync(username.trim(), password, history));
    }
  };

  return (
    <Hero className="login" size="fullheight">
      <Hero.Body>
        <Container>
          <Column.Group centered>
            <Column size={4}>
              <div className="login_logo">
                <img
                  src="/asset/logo.png"
                  alt="Inspify"
                  className="login_logo-img"
                />
              </div>
              <div>
                {error ? (
                  <Notification id="error_msg" color="danger">
                    <Delete as="button" onClick={() => setError(undefined)} />
                    {error?.message && error?.name && error?.code
                      ? error?.message
                      : "Login failed"}
                  </Notification>
                ) : null}
                <Field>
                  <Control className="login_form-control" iconLeft>
                    <input
                      className="login_text-input"
                      id="username"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setUsername(e.target.value)
                      }
                      disabled={loginInProgress}
                      type="text"
                      placeholder="Username"
                      onKeyPress={handleLogin}
                      required
                    />
                    <Icon size="small" align="left">
                      <IconUser />
                    </Icon>
                  </Control>
                </Field>
                <Field>
                  <Control className="login_form-control" iconLeft>
                    <input
                      className="login_text-input"
                      id="password"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(e.target.value)
                      }
                      disabled={loginInProgress}
                      type="password"
                      placeholder="Password"
                      onKeyPress={handleLogin}
                      required
                    />
                    <Icon size="small" align="left">
                      <IconPassword />
                    </Icon>
                  </Control>
                </Field>
                <Field>
                  <Button
                    id="login"
                    text="LOG IN"
                    isLoading={loginInProgress}
                    disabled={!username.trim() || !password || loginInProgress}
                    onClick={() => handleLogin()}
                  />
                </Field>
              </div>
            </Column>
          </Column.Group>
        </Container>
      </Hero.Body>
    </Hero>
  );
}

export default Login;
