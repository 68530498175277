import { IAction, IGeneric } from "../../../@types";
import { ADD_PUBLISHERS } from "../../../utils/constants";
import defaultState from "../initialState";
const genericReducer = (
  state: IGeneric = defaultState?.generic,
  action: IAction<any>
) => {
  switch (action.type) {
    case ADD_PUBLISHERS:
      return { ...state, publishers: action?.payload };
    default:
      return state;
  }
};

export default genericReducer;
