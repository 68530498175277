import React from "react";
import { Spinner } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { IDevice, IMainState, IStore } from "../../@types";
import { logEvent } from "../../analytics";
import { DID_SELECT_DEVICE } from "../../utils/constants";
import { getDeviceLocationStr, getDeviceType } from "../../utils/device";
import DeviceCard from "./DeviceCard";
import { DEVICE_COMMANDS_MAP } from "../../services/device";
import { getDevicesForBrand, getVersions } from "../../redux/actions/devices";
import SearchInput from "../Common/SearchInput";
import { getBrandNameById } from "../../config";

const DevicesList = ({
  brandId,
  selectedDevice,
  setSelectedDevice,
}: {
  brandId: string;
  selectedDevice?: string;
  setSelectedDevice: (id: string) => void;
}) => {
  const isFetching = useSelector(
    (state: IMainState) => state?.devices?.fetching
  );
  const devicesForBrand =
    useSelector((state: IMainState) => state?.devices?.devices)?.[brandId] ||
    {};
  const publishers = useSelector(
    (state: IMainState) => state?.generic?.publishers
  );
  const deviceCommandState = useSelector(
    (state: IMainState) => state?.devices?.deviceCommand
  );
  const stores: IStore[] = useSelector(
    (state: IMainState) => state?.stores?.stores
  )?.[brandId];
  const lastExecutedCommand = deviceCommandState?.executedCommand;
  const filter = (obj, predicate) =>
    Object.fromEntries(Object.entries(obj).filter(predicate));
  const device = devicesForBrand?.[selectedDevice];
  const dispatch = useDispatch();
  const [searchInput, setSearchInput] = React.useState<string>("");
  const handleSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(event.target.value);
  };

  React.useEffect(() => {
    if (lastExecutedCommand?.type === DEVICE_COMMANDS_MAP.REMOVE_DEVICE.type) {
      setSelectedDevice(undefined);
      dispatch(getDevicesForBrand(brandId));
    }
  }, [brandId, dispatch, lastExecutedCommand, setSelectedDevice]);
  React.useEffect(() => {
    setSearchInput("");
    dispatch(getVersions());
  }, [brandId, dispatch]);

  if (device) {
    return (
      <DeviceCard
        devices={device}
        brandId={brandId}
        storeName={stores
          ?.find((store) => store.id === device[0]?.storeId)
          ?.name?.toLowerCase()}
      />
    );
  }

  return (
    <div>
      {!isFetching &&
        (Object.keys(devicesForBrand).length > 0 ? (
          <SearchInput value={searchInput} onChange={handleSearch} />
        ) : (
          <div className="devices_preview empty">
            <p className="devices_preview-info">
              Device not available for {getBrandNameById(publishers, brandId)}
            </p>
          </div>
        ))}
      <div className="devicesByBrand_list">
        {isFetching ? (
          <Spinner animation="border" size="sm" />
        ) : (
          Object.values(
            filter(
              devicesForBrand,
              ([key, obj]) =>
                obj[0]?.deviceId
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase()) ||
                obj[0]?.name
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase()) ||
                obj[0]?.status
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase()) ||
                stores
                  ?.find((store) => store?.id === obj[0]?.storeId)
                  ?.name?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase()) ||
                getDeviceLocationStr(obj[0])
                  ?.toLowerCase()
                  ?.includes(searchInput?.toLowerCase())
            )
          )?.map((devices: IDevice[], index) => (
            <div
              key={index}
              className="devicesByBrand_list-item"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE, DID_SELECT_DEVICE, {
                  device: devices[0]?.deviceId,
                });
                setSelectedDevice(devices[0]?.deviceId);
              }}
            >
              <p className="devicesByBrand_list-item-name">
                {devices[0]?.name}
              </p>
              <p className="devicesByBrand_list-item-deviceType">
                {getDeviceType(devices)}
              </p>
              <p>{devices[0]?.deviceId}</p>
              <span className="devicesByBrand_list-item-version">
                v{devices[0]?.version}
              </span>
              <p className="devicesByBrand_list-item-name">
                Status :{devices[0]?.status}
              </p>
              {getDeviceLocationStr(devices[0]) && (
                <p className="devicesByBrand_list-item-name">
                  City: {getDeviceLocationStr(devices[0])}
                </p>
              )}
              <p className="devicesByBrand_list-item-name">
                {stores
                  ?.find((store) => store.id === devices[0]?.storeId)
                  ?.name?.toLowerCase()}
              </p>
            </div>
          ))
        )}
      </div>
    </div>
  );
};

export default DevicesList;
