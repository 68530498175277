import { combineReducers } from "redux";

import login from "./login";
import devices from "./devices";
import stores from "./stores";
import user from "./user";
import hubUsers from "./hubUsers";
import generic from "./Generic";
const rootReducer = combineReducers({
  login,
  devices,
  stores,
  user,
  hubUsers,
  generic,
});

export default rootReducer;
