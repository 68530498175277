import { IDevice } from "../@types";
import { isEmpty } from "lodash";
import { DeviceType } from "../components/DevicesByBrand/AddDeviceModal";
import { s3BaseUrl } from "../config";

export const getDeviceType = (devices: IDevice[]): DeviceType => {
  const lensebarDevice = devices?.filter(
    (dev) => !isEmpty(dev?.lensebarConfigurationId)
  );

  if (lensebarDevice && !isEmpty(lensebarDevice)) {
    return DeviceType.Lense;
  }

  const deviceType = devices?.filter(
    (dev) => dev?.deviceType !== DeviceType.Bolt
  );
  if (deviceType && !isEmpty(deviceType)) {
    return deviceType[0]?.deviceType;
  }

  return DeviceType.SSP;
};
const bucketKeyExtractor = (imageUrl: string) => {
  const components = imageUrl.split("/");
  const bucket = components[0];
  components.shift();
  const key = components.join("/");
  return { key, bucket };
};
const encodeKeyInUrl = (imageUrl: string) => {
  const components = imageUrl.split("/");
  return components
    .map((c) => {
      if (c.indexOf("#") > -1 || c.indexOf("@") > -1) {
        return encodeURIComponent(c);
      }
      return c;
    })
    .join("/");
};
export const toS3Url = (imageUrl?: string) => {
  if (!imageUrl) {
    return;
  }
  if (imageUrl.startsWith("https")) {
    return encodeKeyInUrl(imageUrl);
  }
  const { key, bucket } = bucketKeyExtractor(imageUrl);
  return `https://${bucket}.${s3BaseUrl}/${encodeURIComponent(key)}`;
};
export function getLocalMediaPath(value: any) {
  if (typeof value === "object") return URL.createObjectURL(value);
  return value;
}
export interface IClock {
  readonly now: () => Date;
}
export const clock: IClock = {
  now: () => new Date(),
};

export const getDeviceLocationStr = (device: IDevice) => {
  if (!device?.location) return;
  const location = device.location;
  return `${location?.city}, ${location?.country}`;
};
