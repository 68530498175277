import { Hero, Container, Column } from "rbx";
import Button from "../Common/Button";
import React from "react";
import { IconArrowFill } from "../Common/Icons";
import { Link } from "react-router-dom";
import { signOut } from "../../services/login";
import { IDENTITY_ID, DID_CLICK_LOGOUT } from "../../utils/constants";
import { useHistory } from "react-router-dom";
import { logEvent } from "../../analytics";

const Dashboard = () => {
  const history = useHistory();

  return (
    <Hero className="dashboard" size="fullheight">
      <Hero.Body>
        <Container>
          <Column.Group centered>
            <Column size={4}>
              <div className="dashboard_main">
                <img
                  src="https://via.placeholder.com/150"
                  alt="avatar"
                  className="dashboard_main-avatar"
                />
                <p className="dashboard_main-hi">Hi Username,</p>
                <p className="dashboard_main-welcome">Welcome back!</p>
                <p className="dashboard_main-select-text">
                  Please select an option:
                </p>
                <Link className="dashboard_main-option" to="/dashboard/devices">
                  Devices <IconArrowFill />
                </Link>
                <Link className="dashboard_main-option" to="/dashboard/hubusers">
                  Sales Hub User Management
                  <IconArrowFill />
                  </Link>
              </div>
            </Column>
          </Column.Group>
        </Container>
        <Button
          text="Sign Out"
          className="dashboard_main_sign-out-btn"
          onClick={() => {
            logEvent(DID_CLICK_LOGOUT, DID_CLICK_LOGOUT, {
              IDENTITY_ID,
            });
            signOut().then(() => {
              localStorage.setItem(IDENTITY_ID, null);
              sessionStorage.setItem("expiry", null);
              history.replace("/");
            });
          }}
        />
      </Hero.Body>
    </Hero>
  );
};

export default Dashboard;
