import defaultState from "../initialState";
import { IAction, IDevices } from "../../../@types";
import {
  FAILED_FETCH_DEVICES_BY_BRAND,
  FETCHING_DEVICES_BY_BRAND,
  SUCCESSFUL_FETCH_DEVICES_BY_BRAND,
  ADD_NEW_DEVICE_REQUEST,
  ADD_NEW_DEVICE_SUCCESS,
  ADD_NEW_DEVICE_FAILURE,
  SEND_DEVICE_COMMAND_REQUEST,
  SEND_DEVICE_COMMAND_SUCCESS,
  SEND_DEVICE_COMMAND_FAILURE,
  SAVE_VERSIONS_TO_REDUX,
  RESET_DEVICE_COMMAND_STATE,
  SELECT_BRAND_FOR_DEVICES_PREVIEW,
  RESET_NEW_DEVICE_REQUEST,
} from "../../../utils/constants";

const loginReducer = (
  state: IDevices = defaultState?.devices,
  action: IAction<any>
) => {
  switch (action.type) {
    case FETCHING_DEVICES_BY_BRAND: {
      return { ...state, fetching: true, error: undefined };
    }
    case SUCCESSFUL_FETCH_DEVICES_BY_BRAND: {
      const { brandId, devices } = action?.payload;
      return {
        ...state,
        fetching: false,
        error: undefined,
        devices: { ...state.devices, [brandId]: devices },
      };
    }
    case FAILED_FETCH_DEVICES_BY_BRAND: {
      return { ...state, fetching: false, error: action.payload };
    }

    case ADD_NEW_DEVICE_REQUEST: {
      return { ...state, isSubmitting: true, error: undefined };
    }
    case RESET_NEW_DEVICE_REQUEST: {
      return { ...state, isSubmitting: false, error: undefined,newDeviceId:undefined};
    }
    case ADD_NEW_DEVICE_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        error: undefined,
        newDeviceId: action?.payload,
      };
    }
    case ADD_NEW_DEVICE_FAILURE: {
      return { ...state, isSubmitting: false, error: action.payload };
    }

    case SEND_DEVICE_COMMAND_REQUEST: {
      return {
        ...state,
        deviceCommand: {
          ...state?.deviceCommand,
          isSubmitting: true,
          executedCommand: undefined,
          error: undefined,
        },
      };
    }
    case SEND_DEVICE_COMMAND_SUCCESS: {
      return {
        ...state,
        deviceCommand: {
          ...state?.deviceCommand,
          isSubmitting: false,
          executedCommand: action?.payload,
          error: undefined,
        },
      };
    }
    case SEND_DEVICE_COMMAND_FAILURE: {
      return {
        ...state,
        deviceCommand: {
          ...state?.deviceCommand,
          isSubmitting: false,
          executedCommand: undefined,
          error: action?.payload,
        },
      };
    }
    case SAVE_VERSIONS_TO_REDUX: {
      return {
        ...state,
        versions: action?.payload,
      };
    }
    case RESET_DEVICE_COMMAND_STATE: {
      return {
        ...state,
        deviceCommand: defaultState?.devices?.deviceCommand,
      };
    }

    case SELECT_BRAND_FOR_DEVICES_PREVIEW: {
      return {
        ...state,
        brandId: action?.payload,
      };
    }

    default:
      return state;
  }
};

export default loginReducer;
