import { Auth } from "aws-amplify";
import apigClientFactory from "aws-api-gateway-client";
import AWS from "aws-sdk";
import { baseUrl as defaultBaseURL, region } from "../../config";

export const getCredentials = async () => {
  const credentials = await Auth.currentCredentials();
  return {
    accessKey: credentials.accessKeyId,
    secretKey: credentials.secretAccessKey,
    sessionToken: credentials.sessionToken,
  };
};

export const getS3Instance = async (): Promise<AWS.S3> => {
  const credentials = await getCredentials();
  return new AWS.S3({
    region,
    credentials: {
      accessKeyId: credentials.accessKey as string,
      secretAccessKey: credentials.secretKey as string,
      sessionToken: credentials.sessionToken as string,
    },
  });
};

export const apigClient = async (baseUrl?: string) => {
  const config = {
    region,
    invokeUrl: baseUrl || defaultBaseURL,
    defaultContentType: "application/json",
    defaultAcceptType: "application/json",
  };

  const credentials = await getCredentials();
  return apigClientFactory.newClient({
    ...config,
    ...credentials,
  });
};
