import {
  faAngleDown,
  faAngleUp,
  faLongArrowAltDown,
  faLongArrowAltUp,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IProduct, IRack } from "../../@types";
import { getLocalMediaPath } from "../../utils/device";
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';

const ProductSlots = ({
  rack,
  handleSwap,
  handleRemove,
}: {
  rack: IRack;
  handleSwap: (myIndex: number, swapIndex: number, type?: string) => void;
  handleRemove: (product: IProduct) => void;
}) => {
  return (
    <>
      {rack?.productSlots?.map((product, i) => (
        <div className="product-row" key={product.id}>
          <div className="row">
            <div className="col-2 image-row ">
              <img
                src={getLocalMediaPath(product.previewImageUrl)}
                alt="Inspify"
                className="product-img"
              />
            </div>
            <div className="col-8 text-row">
              <div className="id-text">{product.modelCode}</div>
              <label className="title-text">{product.modelName}</label>
            </div>

            <div className="col-2 but-row row">
              <div className="col-4 text-row">
                <div
                  className="btn-light arrows-btn"
                  onClick={() => handleSwap(i, i - 1)}
                >
                  <FontAwesomeIcon
                    icon={faAngleUp as any}
                    size="2x"
                    color={i === 0 ? "#fff5" : "#fff"}
                  />
                </div>
                <div
                  className="btn-light arrows-btn"
                  onClick={() => handleSwap(i, i + 1)}
                >
                  <FontAwesomeIcon
                    icon={faAngleDown as any}
                    size="2x"
                    color={
                      i === rack?.productSlots?.length - 1 ? "#fff5" : "#fff"
                    }
                  />
                </div>
              </div>
              <div className="col-4 text-row">
                <div
                  className="btn-light arrows-btn"
                  onClick={() => handleSwap(i, 0,'top')}
                >
                  <FontAwesomeIcon
                    icon={faLongArrowAltUp as any}
                    size="2x"
                    color={i === 0 ? "#fff5" : "#fff"}
                  />
                </div>
                <div
                  className="btn-light arrows-btn"
                  onClick={() => handleSwap(i, rack?.productSlots?.length - 1,'bottom')}
                >
                  <FontAwesomeIcon
                    icon={faLongArrowAltDown as any}
                    size="2x"
                    color={
                      i === rack?.productSlots?.length - 1 ? "#fff5" : "#fff"
                    }
                  />
                </div>
              </div>
              <div
                className="col-4 btn-light"
                onClick={() => handleRemove(product)}
              >
                X
              </div>
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default ProductSlots;
