import { Auth } from "aws-amplify";
import "bootstrap/dist/css/bootstrap.min.css";
import "rbx/index.css";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./components/App";
import initialState from "./redux/reducers/initialState";
import configureStore from "./redux/store";
import reportWebVitals from "./reportWebVitals";
import "./style/index.css";

const { store } = configureStore(initialState);

const awsConfig = {
  Auth: {
    identityPoolId: "ap-southeast-1:6b624139-ad46-4d58-9f5c-0eaaa8cf72fa",
    region: "ap-southeast-1",
    userPoolId: "ap-southeast-1_sRVaLUIf6",
    userPoolWebClientId: "6impkl0vpsgna82go4dpg2b313",
    mandatorySignIn: false,
  },
};

Auth.configure(awsConfig);

Auth.currentAuthenticatedUser()
  .then(user => console.log('User is signed in:', user))
  .catch(err => console.log('User is not signed in'));

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <App />
      <ToastContainer position="top-center" hideProgressBar={true} />
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
