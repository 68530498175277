import { IPublisher } from "../@types";

export const iwcBrandId = "fe37f5a8-5648-4930-9e66-5c55e9b26dfd";
export const alsBrandId = "33e8880d-64c0-4a84-afcf-de8d5511cc10";
export const piagetBrandId = "3de1a511-8a84-458b-80f5-ee73269246f6";
export const jlcBrandId = "edccdb6b-31c9-4200-b679-6d195a6beccc";
export const lvBrandId = "10d7a048-a1f3-46b1-ba4b-ad1d448fd6ab";
export const rduBrandId = "4bba131d-6238-4c54-b4e4-506bc199d13d";
export const louboutinBrandId = "476bd411-03e9-4e6f-8c8b-8c51d1e0f5e1";
export const zegnaBrandId = "4a6f65c4-92d8-4c85-a907-da3cb61842c6";
export const chopardBrandId = "e2a99213-7ee9-4c0b-9169-b751098d458f";
export const inspifyBrandId = "1bc913e3-faf3-47c3-a097-074905a292fd";
export const vacBrandId = "e0f98b18-4e8e-474a-8c99-d0afe9ad4eb5";
export const cartierBrandId = "24243547-b586-48d0-a03f-06ef684e5b4c";
export const vanCleefArpelsBrandId = "bd530325-565c-4323-b8f7-caacca4ad9a0";
export const aspreyBrandId = "4bba131d-b24b-4bbb-bc32-b4da62f51df8";
export const tiffanyBrandId = "2886a335-2075-44c2-bdf5-16e62ee7f673";
export const nansenBrandId = "eb867e26-14e5-4894-aee3-924902b37059";
export const gustoLuxeBrandId = "52695cf8-9ea9-4d02-8dc5-3b0a1bdefcb3";
export const inspifyTestBrandId = "65c50009-143d-4325-a40a-78aebff5e43b";

export type BrandConfig = {
  id: string;
  name: string;
}[];

export const allBrands: BrandConfig = [
  {
    id: iwcBrandId,
    name: "IWC Schaffhausen",
  },
  {
    id: piagetBrandId,
    name: "Piaget",
  },
  {
    id: rduBrandId,
    name: "Roger Dubuis",
  },
  {
    id: chopardBrandId,
    name: "Chopard",
  },
  {
    id: inspifyBrandId,
    name: "INSPIFY",
  },
  {
    id: inspifyTestBrandId,
    name: "INSPIFY",
  },
  {
    id: vacBrandId,
    name: "Vacheron Constantin",
  },
  {
    id: cartierBrandId,
    name: "Cartier",
  },
  {
    id: aspreyBrandId,
    name: "Asprey",
  },
  {
    id: vanCleefArpelsBrandId,
    name: "Van Cleef Arpels",
  },
  {
    id: alsBrandId,
    name: "A. Lange & S\u00f6hne",
  },
  {
    id: jlcBrandId,
    name: "Jaeger Le-Coultre",
  },
  {
    id: zegnaBrandId,
    name: "Ermenegildo Zegna",
  },
  {
    id: louboutinBrandId,
    name: "Christian Louboutin",
  },
  {
    id: tiffanyBrandId,
    name: "Tiffany & Co.",
  },
  {
    id: nansenBrandId,
    name: "Nansen",
  },
  {
    id: gustoLuxeBrandId,
    name: "Gusto Luxe",
  },
];

export const getBrandNameById = (publishers: IPublisher[], brandId: string) => {
  return publishers?.find((brand) => brandId === brand.id)?.alias;
};

export const region = "ap-southeast-1";

export const prodBaseUrl =
  "https://bbgcbbyk64.execute-api.ap-southeast-1.amazonaws.com/prod";

export const baseUrl =
  "https://41a0cd9pa5.execute-api.ap-southeast-1.amazonaws.com/dev";
export const s3BaseUrl = `s3.${region}.amazonaws.com`;
