import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IDevice, IMainState } from "../../@types";
import Button from "../Common/Button";
import { Command } from "../../services/device";
import { sendDeviceCommand } from "../../redux/actions/devices";
import { getDeviceType } from "../../utils/device";
import { logEvent } from "../../analytics";
import { DID_EXECUTE_DEVICE_COMMAND } from "../../utils/constants";
import { DeviceType } from "./AddDeviceModal";
const DevideUpdateDensity = ({
  device,
  onClose,
  command,
  type,
}: {
  device: IDevice;
  onClose: () => void;
  command: Command;
  type: DeviceType;
}) => {
  const dispatch = useDispatch();
  const deviceCommandState = useSelector(
    (state: IMainState) => state?.devices?.deviceCommand
  );

  const [density, setDensity] = React.useState(120);

  const isSubmittingCommand = deviceCommandState?.isSubmitting;
  const deviceType = getDeviceType([device]);
  const onConfirmCommand = () => {
    const commandWithArguments: Command = {
      ...command,
      argumments: {
        dpi: density,
      },
    };

    logEvent(DID_EXECUTE_DEVICE_COMMAND, DID_EXECUTE_DEVICE_COMMAND, {
      device: device?.deviceId,
      command: commandWithArguments,
    });

    dispatch(
      sendDeviceCommand(device?.deviceId, deviceType, commandWithArguments)
    );
  };
  return (
    <div className="update-reboot-content">
      <div
        className="form-group reboot-form flex m-auto"
        style={{
          width: "300px",
          maxWidth: "100vw",
        }}
      >
        <div className="reboot-for__row">
          <label>Density </label>
          <input
            type="number"
            className="form-control form-control-sm"
            min={360}
            max={2440}
            onChange={(e) => setDensity(parseInt(e.target.value))}
          />
        </div>
      </div>
      <div className="reboot-footer mt-3">
        <Button
          id="Back"
          text="Back"
          border
          onClick={onClose}
          className="prompt-modal-content-footer-btn"
        />
        <Button
          id="Proceed"
          text="Proceed"
          isLoading={isSubmittingCommand}
          onClick={onConfirmCommand}
          className="prompt-modal-content-footer-btn"
        />
      </div>
    </div>
  );
};

export default DevideUpdateDensity;
