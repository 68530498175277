import React from "react";

const TextInput = ({
  onChange,
  value,
  onKeyPress,
  id,
  placeholder,
  name,
  disabledEdit,
  type = "text",
}: {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onKeyPress?: () => void;
  id?: string;
  placeholder?: string;
  name?: string;
  disabledEdit?: boolean;
  type?: string;
}) => {
  return (
    <div className="textInput">
      <input
        placeholder={placeholder || "Enter text"}
        name={name}
        type={type}
        id={id}
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
        disabled={disabledEdit || false}
      />
    </div>
  );
};

export default TextInput;
