import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMainState, IHubUser } from "../../@types";
import { Command } from "../../services/device";
import Button from "../Common/Button";
import Modal from "../Common/Modal";
import { toast } from "react-toastify";
import { logEvent } from "../../analytics";
import { HUB_USER_COMMANDS_MAP } from "../../services/hunUser";
import { DID_EXECUTE_HUB_USER_COMMAND } from "../../utils/constants";
import {
  actionDeleteHubUserById,
  actionResetHubUserCommandState,
} from "../../redux/actions/hubUsers";
const HubUserCommandModal = ({
  hubUser,
  command,
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  command: Command;
  hubUser: IHubUser;
}) => {
  enum ModalContentType {
    PROMPT_MODAL_CONTENT = "PROMPT_MODAL_CONTENT",
    UPDATE_MODAL_CONTENT = "UPDATE_MODAL_CONTENT",
  }
  const dispatch = useDispatch();
  const hubUserCommandState = useSelector(
    (state: IMainState) => state?.hubUsers?.hubUserCommand
  );
  const isSubmittingCommand = hubUserCommandState?.isSubmitting;
  const sendCommandError = hubUserCommandState?.error;
  const lastExecutedCommand = hubUserCommandState?.executedCommand;
  let title = null;
  let modalBodyDescription = "";
  let modalContentType: ModalContentType = null;
  switch (command?.type) {
    case HUB_USER_COMMANDS_MAP.UPDATE_HUB_USER.type:
      title = "Update SSP";
      break;
    case HUB_USER_COMMANDS_MAP.REMOVE_HUB_USER.type:
      title = "Remove Hub User";
      modalBodyDescription = "Are you sure you want to remove Hub User?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
  }

  const onConfirmCommand = () => {
    logEvent(DID_EXECUTE_HUB_USER_COMMAND, DID_EXECUTE_HUB_USER_COMMAND, {
      hubUser: hubUser?.id,
      command,
    });
    switch (command.type) {
      case HUB_USER_COMMANDS_MAP.REMOVE_HUB_USER.type:
        dispatch(actionDeleteHubUserById(hubUser?.id, command));
        break;
      case HUB_USER_COMMANDS_MAP.UPDATE_HUB_USER.type:
        break;
    }
  };
  const promptModalContent = () => {
    return (
      <div className="prompt-modal-content">
        <p style={{ color: "white" }}>{modalBodyDescription}</p>
        <div className="prompt-modal-content-footer">
          <Button
            id="Back"
            text="Back"
            border
            onClick={onClose}
            className="prompt-modal-content-footer-btn"
          />
          <Button
            id="Proceed"
            text="Proceed"
            isLoading={isSubmittingCommand}
            onClick={onConfirmCommand}
            className="prompt-modal-content-footer-btn"
          />
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if (sendCommandError || lastExecutedCommand) {
      onClose();
    }
  }, [sendCommandError, lastExecutedCommand, onClose]);

  React.useEffect(() => {
    if (lastExecutedCommand) {
      toast.success("Command sent successfully. ", {
        autoClose: 5000,
      });
    }
  }, [lastExecutedCommand]);

  React.useEffect(() => {
    if (sendCommandError)
      toast.error(
        sendCommandError?.message
          ? sendCommandError?.message
          : "Unable to send command",
        {
          autoClose: 5000,
        }
      );
  }, [sendCommandError]);
  React.useEffect(() => {
    return () => {
      dispatch(actionResetHubUserCommandState());
    };
  }, [dispatch]);
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      showClose={true}
      className="DeviceCommandModal"
    >
      {modalContentType === ModalContentType.PROMPT_MODAL_CONTENT &&
        promptModalContent()}
      {modalContentType === ModalContentType.UPDATE_MODAL_CONTENT}
    </Modal>
  );
};

export default HubUserCommandModal;
