import { DeviceType } from "../components/DevicesByBrand/AddDeviceModal";
import { Command } from "../services/device";

export interface IAction<T> {
  type: string;
  payload: T;
}

export interface ILoginState {
  readonly inProgress: boolean;
  readonly error?: Error;
  readonly authenticated: boolean;
}

export interface IPublisherPayload {
  readonly ownerId: string;
  readonly ownerAliasName: string;
  readonly ownerAvatar_picture?: string;
  readonly ownerFirstName?: string;
  readonly ownerLastName?: string;
  readonly publisherId?: string;
  readonly parentPublisherId?: string;
  readonly type?: string;
}
export interface IPublisher {
  readonly id: string;
  readonly alias: string;
  readonly parentPublisherId?: string;
  readonly avatar?: string | File;
  readonly type?: string;
}
export interface IUser {
  readonly id: string;
  readonly name: string;
  readonly location?: string;
  readonly avatarUrl?: string;
  readonly brandLogoUrl?: string;
  readonly brandName?: string;
  readonly brandId?: string;
  readonly authorForBrands?: string[];
  readonly selectivelyAllowedPages?: string[];
  readonly allowCrossBrandStoryProducts?: boolean;
}

export interface IEntitlement {
  readonly entitlementType: string;
  uuid: string;
}

export interface IHubUser {
  readonly id: string;
  readonly user_name: string;
  readonly alias?: string;
  readonly email?: string;
  readonly userType?: string;
  readonly enabled?: number;
  readonly first_name?: string;
  readonly last_name?: string;
  readonly full_name?: string;
  readonly mobilePhoneCountryCode?: string;
  readonly mobileNumber?: string;
  readonly createdAt?: string;
  readonly modifiedAt?: string;
  readonly language?: string;
  readonly entitlements?: IEntitlement[];
  readonly avatar_picture?: string;
}
export interface IGroupedHubUserMap {
  readonly [key: string]: IHubUser[];
}
export interface IHubUserMap {
  readonly [key: string]: IGroupedHubUserMap;
}

export interface IDeviceMap {
  readonly [key: string]: IGroupedDeviceMap;
}

export interface IGroupedDeviceMap {
  readonly [key: string]: IDevice[];
}
export interface IDeviceLocation {
  city: string;
  country: string;
  region: string;
}
export interface IDevice {
  readonly id: string;
  readonly applicationName: DeviceType;
  readonly artifactURL?: string;
  readonly connectionId?: string;
  readonly deviceId: string;
  readonly lensebarConfigurationId?: string;
  readonly name?: string;
  readonly deviceType?: DeviceType;
  readonly version: string;
  readonly createdAt?: string;
  readonly createdBy?: string;
  readonly modifiedAt?: string;
  readonly modifiedBy?: string;
  readonly status?: string;
  readonly storeId: string;
  readonly lastOnlineTime?: string;
  readonly grafanaLink?: string;
  readonly inventories?: Inventory[];
  readonly location?: IDeviceLocation;
  readonly brand?: string;
}
export interface Inventory {
  readonly id: string;
  readonly applicationName: string;
  readonly artifactURL?: string;
  readonly pinCode?: string;
  readonly lensebarConfigurationId?: string;
  readonly brand?: string;
  readonly storeId?: string;
  readonly version?: string;
  readonly deviceId?: string;
  readonly connectionId?: string;
  readonly createdAt?: string;
  readonly createdBy?: string;
  readonly modifiedAt?: string;
  readonly modifiedBy?: string;
}

export interface StoreBrand {
  readonly brandId: string;
  readonly item_types: string[];
}

export interface IStore {
  readonly id: string;
  readonly brands: StoreBrand[];
  readonly name: string;
  readonly mall: string;
  readonly description: string;
  readonly whatsAppShareMessage: string;
  readonly chatNowNumber: string;
  readonly countryCode: string;
  readonly city?: string;
  readonly languageCode?: string;
  readonly email: string;
  readonly currencyCode: string;
  readonly openingHours: {
    readonly sunday: string;
    readonly monday: string;
    readonly tuesday: string;
    readonly wednesday: string;
    readonly thursday: string;
    readonly friday: string;
    readonly saturday: string;
  };
}

export interface IStoresMap {
  readonly [key: string]: IStore[];
}

interface ICommandState {
  readonly error?: Error;
  readonly executedCommand: Command;
  readonly isSubmitting: boolean;
}

export interface IDevices {
  readonly brandId?: string;
  readonly fetching: boolean;
  readonly error?: Error;
  readonly devices: IDeviceMap;
  readonly isSubmitting: boolean;
  readonly newDeviceId?: string;
  readonly deviceCommand?: ICommandState;
  readonly versions?: IVersionsMap;
}

export enum LenseTheme {
  dark = "DARK",
  light = "LIGHT",
}
export interface IRack {
  readonly id?: string;
  readonly name?: string;
  readonly description?: string;
  readonly productSlots?: IProduct[];
  readonly storeId?: string;

  readonly createdBy?: string;
  modifiedBy?: string;
  readonly createdAt?: string;
  readonly modifiedAt?: string;

  readonly mosaicImage?: string;

  readonly colourScheme?: LenseTheme;
  readonly entertainmentModeIdleTime?: number;
  readonly layout?: {
    readonly slots_x_axis?: number;
    readonly slots_y_axis?: number;
  };

  readonly beacon_minor?: string;
  readonly beacon_uuid?: string;
  readonly itemsNumber?: number;
  readonly showcaseVideos?: string[];
}
export interface IRackProductSlot {
  readonly productId: string;
  readonly slot: number;
}
export interface IRackPayload {
  readonly id: string;
  readonly name?: string;
  readonly description?: string;
  readonly storeId?: string;
  readonly entertainmentModeIdleTime?: number;
  readonly layout?: {
    readonly slots_x_axis?: number;
    readonly slots_y_axis?: number;
  };
  readonly productSlots?: IRackProductSlot[];
  readonly colourScheme?: LenseTheme;
  readonly createdBy?: string;
  readonly createdAt?: string;
  readonly modifiedBy?: string;
  readonly modifiedAt?: string;
  readonly beacon_minor?: string;
  readonly beacon_uuid?: string;
  readonly showcaseVideos?: string[];
}
export interface IWatchSpecifications {
  readonly [key: string]: {
    readonly languageCode: string;
    readonly name: string;
    readonly description: string;
    readonly longDescription: string;
    readonly material: string;
    readonly color: string;
    readonly languageLink?: string;
    readonly productLinkOwnerWebsite?: string;
    readonly caseSpecs: string[];
    readonly movementSpecs: string[];
    readonly featuresSpecs: string[];
    readonly dialSpecs: string[];
    readonly strapSpecs: string[];
    readonly story: string;
  };
}

export interface IJewelrySpecifications {
  readonly [key: string]: {
    readonly languageCode: string;
    readonly name: string;
    readonly description: string;
    readonly longDescription: string;
    readonly material: string;
    readonly color: string;
    readonly type?: string;
    readonly languageLink?: string;
    readonly productLinkOwnerWebsite?: string;
    readonly preciousStones: string[];
    readonly length: string[];
    readonly size: string[];
    readonly width: string[];
    readonly weight: string[];
    readonly story: string;
    readonly other?: string[];
  };
}
export interface IOfficialSalesPrices {
  readonly [key: string]: {
    readonly countryCode: string;
    readonly currencyCode: string;
    readonly salesPrice: number;
    readonly productLink?: string;
    readonly productLinkOwnerWebsite?: string;
  };
}

export interface IProductEditions {
  readonly [key: string]: {
    readonly edition: string;
  };
}
export enum ProductStatus {
  draft = "draft",
  active = "active",
  staged = "staged",
  updated = "updated",
  discontinued = "discontinued",
  deactivated = "deactivated",
}
export enum ConfigSetting {
  colourScheme = "colourScheme",
  entertainmentModeIdleTime = "entertainmentModeIdleTime",
  slots_x_axis = "slots_x_axis",
  slots_y_axis = "slots_y_axis",
}
export interface ISearchResult<T> {
  readonly results: T[];
  readonly totalCount: number;
  readonly lastIndex: number;
}
export interface IProductFilters {
  status?: ProductStatus[];
}

export interface IProduct {
  readonly awsLabels?: string[];
  readonly beacon_minor?: string;
  readonly beacon_uuid?: string;
  readonly brandId?: string;
  readonly brandName?: string;
  readonly colorText?: string;
  readonly currency?: string;
  readonly description?: string;
  readonly dimensions?: any;
  readonly editions?: IProductEditions;
  readonly gender?: string;
  readonly id: string;
  readonly longDescription?: string;
  readonly material?: string;
  readonly modelCode?: string;
  readonly modelGroup?: string;
  readonly modelName?: string;
  readonly officialSalesPrices?: IOfficialSalesPrices;
  readonly productColorImageURL?: string | File;
  readonly productFamily?: string;
  readonly previewImageUrl?: string | File;
  readonly imageUrls?: (string | File)[];
  readonly salesPriceSG?: number;
  readonly status?: string;
  readonly tag?: string;
  readonly timestamp?: string;
  readonly type?: string;
  readonly watchSpecifications?: IWatchSpecifications;
  readonly jewelrySpecification?: IJewelrySpecifications;

  readonly offeringPrice?: number;
  readonly offeringCurrency?: string;

  readonly imageHiResUrls?: (string | File | undefined)[];
  readonly lookBook?: string | File;

  readonly originalImageUrlsLength?: number;
}

export interface IHubUsers {
  readonly brandId?: string;
  readonly fetching: boolean;
  readonly error?: Error;
  readonly hubUsers: IHubUserMap;
  readonly isSubmitting: boolean;
  readonly hubUserCommand?: ICommandState;
  readonly newHubUser?;
}

export interface IStores {
  readonly fetching: boolean;
  readonly error?: Error;
  readonly stores: IStoresMap;
}

export interface IVersion {
  name: string;
  version: string;
  url: string;
  chinaUrl: string;
}

export interface IVersionsMap {
  SSP: IVersion[];
  Lense: IVersion[];
  Bolt: IVersion[];
  DiamondLight: IVersion[];
  LenseScreen: IVersion[];
}

export interface IGeneric {
  readonly publishers?: IPublisher[];
}
export interface IMainState {
  readonly login?: ILoginState;
  readonly user?: IUser;
  readonly devices?: IDevices;
  readonly stores?: IStores;
  readonly hubUsers?: IHubUsers;
  readonly generic?: IGeneric;
}
export interface ICreateUserPayload {
  username: string;
  storeId: string;
  attributes: {
    email: string;
    email_verified: "true" | "false";
  };
}

export enum PageView {
  DASHBOARD = "DASHBOARD",
  DEVICES = "DEVICES",
  DEVICE_DETAILS_ON_MOBILE = "DEVICE_DETAILS_ON_MOBILE",
  USERS = "USERS",
  USERS_DETAILS_ON_MOBILE = "USERS_DETAILS_ON_MOBILE",
}
export enum RecordingEntitlement {
  PRIVATE = "PRIVATE",
  SITE = "SITE",
  BRAND = "BRAND",
}
