import React from "react";
import { Spinner } from "react-bootstrap";
import {
  ConfigSetting,
  IDevice,
  IProduct,
  IRack,
  LenseTheme,
} from "../../@types";
import { loadRackById, saveRack } from "../../services/product";
import Modal from "../Common/Modal";
import ProductSearch from "./ProductSearch";
import ProductSlots from "./ProductSlots";
import ShowcaseVideo from "./ShowcaseVideo";
import { toast } from "react-toastify";
import InteractiveDisplaySetting from "./InteractiveDisplaySetting";
import {
  DEVICE_COMMANDS_MAP,
  sendLenseCommandService,
} from "../../services/device";
import { DeviceType } from "../DevicesByBrand/AddDeviceModal";
import ConfigHeader from "./ConfigHeader";
import { logEvent } from "../../analytics";
import { DID_CLICK_BUTTON } from "../../utils/constants";
const DeviceConfigurations = ({
  isOpen,
  onClose,
  configurationId,
  device,
}: {
  isOpen: boolean;
  onClose: () => void;
  configurationId?: string;
  device?: IDevice;
}) => {
  const [rack, setRack] = React.useState<IRack>();
  const [state, setState] = React.useState({
    isUpdating: 0,
    confirm: false,
    baseRack: {} as IRack,
  });
  const loadRack = async () => {
    const rack = await loadRackById(configurationId);
    setRack(rack);
    setState({ ...state, baseRack: rack });
  };
  const handleSwap = (myIndex: number, swapIndex: number, type?: string) => {
    if (swapIndex < 0 || swapIndex >= rack.productSlots.length) return;

    const currentSlots = [...rack.productSlots];
    if (type === "top") {
      const currentSlotsTop = [...currentSlots];
      currentSlotsTop.splice(myIndex, 1);
      setRack({
        ...rack,
        productSlots: [currentSlots[myIndex], ...currentSlotsTop],
      });
      return;
    }
    if (type === "bottom") {
      const currentSlotsTop = [...currentSlots];
      currentSlotsTop.splice(myIndex, 1);
      setRack({
        ...rack,
        productSlots: [...currentSlotsTop, currentSlots[myIndex]],
      });
      return;
    }

    const temp = currentSlots[myIndex];
    currentSlots[myIndex] = currentSlots[swapIndex];
    currentSlots[swapIndex] = temp;
    setRack({ ...rack, productSlots: currentSlots });
  };
  const handleRemove = (product: IProduct) => {
    const newProductSlots = [...rack?.productSlots].filter(
      (p) => p !== product
    );
    const newRack: IRack = { ...rack, productSlots: newProductSlots };
    setRack(newRack);
  };
  const handleSave = () => {
    setState({ ...state, isUpdating: 1 });
    saveRack(rack)
      .then((result) => {
        setState({ ...state, isUpdating: 2 });
        toast.success("Saved successfully.", {
          autoClose: 5000,
        });
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: "Device configuration save",
          payload: rack,
        });

        console.log("saveRack~~", result);
        return sendLenseCommandService(
          device?.deviceId,
          DEVICE_COMMANDS_MAP.LENSE_CONFIGURATION_UPDATE,
          DeviceType.Lense
        );
      })
      .then((result) => {
        console.log("sendLenseCommandService~~", result);
        logEvent(DID_CLICK_BUTTON, DID_CLICK_BUTTON, {
          button: "Send command Configurations.",
          payload: {
            deviceId: device?.deviceId,
            command: DEVICE_COMMANDS_MAP.LENSE_CONFIGURATION_UPDATE,
            deviceType: DeviceType.Lense,
          },
        });

        toast.success("Send command Configurations.", {
          autoClose: 5000,
        });
        setState({ ...state, isUpdating: 0, baseRack: rack });
      })
      .catch((e) => {
        toast.error(
          `${
            state.isUpdating === 1 ? "Fail to Save" : "Fail to Send command"
          } configuration.`,
          {
            autoClose: 5000,
          }
        );
        setState({ ...state, isUpdating: 0, baseRack: rack });
        console.log("error~~", e);
      });
  };
  const handleAdd = (product: IProduct) => {
    const newProductSlots = [
      ...[...rack?.productSlots].filter((p) => p !== product),
      product,
    ];
    const newRack: IRack = { ...rack, productSlots: newProductSlots };
    setRack(newRack);
  };
  const addVideoLink = (link: string) => {
    if (link?.length > 0) {
      setRack({ ...rack, showcaseVideos: [...rack.showcaseVideos, link] });
    }
  };
  const removeVideoLink = (video: string, index: number) => {
    const videos = [...rack.showcaseVideos];
    videos.splice(index, 1);
    setRack({ ...rack, showcaseVideos: videos });
  };
  const handleLayout = (type: string, value: string) => {
    switch (type) {
      case ConfigSetting.colourScheme:
        setRack({ ...rack, colourScheme: value as LenseTheme });
        break;
      case ConfigSetting.entertainmentModeIdleTime:
        setRack({ ...rack, entertainmentModeIdleTime: Number(value) });
        break;
      case ConfigSetting.slots_x_axis:
        setRack({
          ...rack,
          layout: { ...rack.layout, slots_x_axis: Number(value) },
        });
        break;
      case ConfigSetting.slots_y_axis:
        setRack({
          ...rack,
          layout: { ...rack.layout, slots_y_axis: Number(value) },
        });
        break;
    }
  };
  React.useEffect(() => {
    loadRack();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <Modal
      isOpen={isOpen}
      onClose={() => {
        if (state.baseRack === rack || !rack) {
          onClose();
        } else {
          setState({ ...state, confirm: true });
        }
      }}
      title={"Device Configuration"}
      showClose={true}
      className="DeviceConfiguration"
      disableClose={state.isUpdating !== 0}
    >
      <div className="row">
        <ConfigHeader rack={rack} setRack={setRack} />
      </div>
      <div className="row">
        <div className="col-7 lense-bar-product overflow-auto">
          {!rack && <Spinner animation="border" size="sm" />}
          <ProductSlots
            rack={rack}
            handleSwap={handleSwap}
            handleRemove={handleRemove}
          />
        </div>
        <div className="col-5 product-search">
          <ProductSearch rack={rack} handleAdd={handleAdd} />
        </div>
      </div>
      <ShowcaseVideo
        rack={rack}
        addVideoLink={addVideoLink}
        removeVideoLink={removeVideoLink}
      />
      <InteractiveDisplaySetting rack={rack} handleLayout={handleLayout} />
      <div>
        <button
          className="col-4 btn common-button save-btn btn-solid"
          onClick={handleSave}
          disabled={state.isUpdating > 0 || !rack}
        >
          {state.isUpdating ? <Spinner animation="border" size="sm" /> : "SAVE"}
        </button>
      </div>

      {state.confirm && (
        <Modal
          isOpen={state.confirm}
          onClose={() => setState({ ...state, confirm: false })}
          title={"Your changes will be lost!"}
          showClose={true}
          className="warning-popup"
        >
          <div>
            <p className="warning-title">
              All your change will not saved. Are you sure want to quit?
            </p>
            <br />
            <button
              className="col-4 btn common-button confirm-btn"
              onClick={onClose}
              disabled={state.isUpdating > 0 || !rack}
            >
              Quit
            </button>
            <button
              className="col-4 btn common-button confirm-btn"
              onClick={() => setState({ ...state, confirm: false })}
              disabled={state.isUpdating > 0 || !rack}
            >
              Continue editing
            </button>
          </div>
        </Modal>
      )}
      <style>
        {`
          .btn-light
          {
            background: transparent;
            border: transparent;
            color: white;
            padding: 0px;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          .btn-light:hover
          {
            background: transparent;
            border: transparent;
            color: white;
            color: black;
          }
          .custom-modal .modal-content .title {
            margin-bottom: 10px;
          }
          .DeviceConfiguration{
            position: fixed;
          }
          
          .warning-popup .modal-content{
            max-width: 550px;
          }
          .btn-solid{
            max-height:40px;
            margin-top: 5px;
          }
          .save-btn{
            max-width:200px; 
            margin-top:0;
          }
          .confirm-btn{
            max-width:200px; 
            margin-top:0;
            margin: 0 10px;
          }
          .text-avail{
            text-align: left;
          }
          .text-found{
            text-align: right;
          }
          .search-result{
            height:calc(50vh - 70px);
            overflow-x: hidden; 
          }
          .lense-bar-product{
            height:50vh;
            background-color: #ffffff06;
          }
          .product-row{
            padding: 5px;
            background-color: #ffffff10;
            margin-top: 5px;
          }
          .btn-up{
            transform: rotate(180deg);
          }
          .text-row{
            text-align: left;
            display: flex;
            justify-content: center;
            flex-direction: column;
          }
          .found-result{
            height:30px;
            padding-top:6px;
            
          }
          .product-search-input{
            height:40px;
          }
          .but-row{
            color: #fff;
            padding:0px;
          }
          .solid {
            margin: 0px;
            transform: translateY(-2px);
          }
          .id-text {
            color: gray;
            font-size: 0.85rem;
          }
          .title-text {
            color: #fff;
            font-size: 0.85rem;
          }
          .product-img {
            width: 100px;
            height: auto;
          }
          .arrows-btn{
            align-items: center;
          }
    `}
      </style>
    </Modal>
  );
};

export default DeviceConfigurations;
