import React from "react";
import { Spinner } from "react-bootstrap";
import { IProduct, IRack, ISearchResult } from "../../@types";
import { searchForProducts } from "../../services/product";
import { getLocalMediaPath } from "../../utils/device";

const ProductSearch = ({
  rack,
  handleAdd,
}: {
  rack: IRack;
  handleAdd: (product: IProduct) => void;
}) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const [searchQuery, setSearchQuery] = React.useState("");
  const [searchProductsResult, setSearchProductsResult] =
    React.useState<ISearchResult<IProduct>>();
  const [searchProducts, setSearchProducts] = React.useState<IProduct[]>();
  const handleKeyDown = async (
    event: React.KeyboardEvent<HTMLInputElement>
  ) => {
    if (event.keyCode === 13) {
      setIsLoading(true);
      const products = await searchForProducts({ keywords: searchQuery || "" });
      setSearchProductsResult(products);
      var untaggedProducts = products?.results.filter((product) =>
        rack?.productSlots.every((rProduct) => rProduct.id !== product.id)
      );
      setSearchProducts(untaggedProducts);
      setIsLoading(false);
    }
  };
  const handleLoadMore = async () => {
    setIsLoading(true);
    const products = await searchForProducts({
      keywords: searchQuery || "",
      start: searchProductsResult.lastIndex + 1,
    });
    if (!products) return;
    const loadProducts: IProduct[] = [
      ...searchProductsResult.results,
      ...products.results,
    ];
    setSearchProductsResult({
      results: loadProducts,
      lastIndex: products.lastIndex,
      totalCount: products.totalCount,
    });
    var untaggedProducts = products?.results.filter((product) =>
      rack?.productSlots.every((rProduct) => rProduct.id !== product.id)
    );
    setSearchProducts(untaggedProducts);
    setIsLoading(false);
  };
  return (
    <>
      <input
        type="text"
        className="form-control product-search-input"
        placeholder="Search"
        onChange={(event) => setSearchQuery(event.target.value)}
        value={searchQuery}
        onKeyDown={handleKeyDown}
      />
      <div className="row found-result">
        <div className="col-6 text-avail">All Available Products</div>
        <div className="col-6 text-found">
          {searchProductsResult?.totalCount} product(s) found
        </div>
      </div>
      <div className="search-result">
        {isLoading && !searchProductsResult?.results?.length && (
          <Spinner animation="border" size="sm" />
        )}
        {searchProducts?.map((product) => (
          <div className="product-row" key={product.id}>
            <div className="row">
              <div className="col-2 image-row ">
                <img
                  src={getLocalMediaPath(product.previewImageUrl)}
                  alt="Inspify"
                  className="product-img"
                />
              </div>
              <div className="col-8 text-row">
                <div className="col-8 text-row">
                  <div className="id-text">{product.modelCode}</div>
                  <label className="title-text">{product.modelName}</label>
                </div>
              </div>

              <div className="col-2">
                <button
                  className="btn common-button btn-solid"
                  onClick={() => {
                    handleAdd(product);
                    const newProductSlots = [
                      ...[...rack?.productSlots].filter((p) => p !== product),
                      product,
                    ];
                    setSearchProducts(
                      searchProducts?.filter(
                        (p: IProduct) => newProductSlots?.indexOf(p) < 0
                      )
                    );
                  }}
                >
                  ADD
                </button>
              </div>
            </div>
          </div>
        ))}
        {searchProductsResult?.results?.length <
          searchProductsResult?.totalCount && (
          <button
            className="col-4 btn common-button save-btn border-variant btn-solid"
            onClick={handleLoadMore}
            disabled={isLoading}
          >
            {isLoading ? <Spinner animation="border" size="sm" /> : "Load More"}
          </button>
        )}
      </div>
    </>
  );
};

export default ProductSearch;
