import defaultState from "../initialState";
import { IAction, ILoginState } from "../../../@types";
import {
  LOGIN_START,
  LOGIN_SUCCESSFUL,
  LOGIN_FAILED,
} from "../../../utils/constants";

const loginReducer = (
  state: ILoginState = defaultState?.login,
  action: IAction<any>
) => {
  switch (action.type) {
    case LOGIN_START: {
      return { ...state, inProgress: true, error: undefined };
    }
    case LOGIN_SUCCESSFUL: {
      return {
        ...state,
        inProgress: false,
        error: undefined,
      };
    }
    case LOGIN_FAILED: {
      return { ...state, inProgress: false, error: action.payload };
    }
    default:
      return state;
  }
};

export default loginReducer;
