import React from "react";
import { isEmpty } from "lodash";
import { IDevice } from "../../@types";
import { Command, DEVICE_COMMANDS_MAP } from "../../services/device";
import { getDeviceType } from "../../utils/device";
import Button from "../Common/Button";
import { DeviceType } from "./AddDeviceModal";
import DeviceCommandModal from "./DeviceCommandModal";
import { getDevicesForBrand, resetDeviceCommandState } from "../../redux/actions/devices";
import { useDispatch } from "react-redux";
import { logEvent } from "../../analytics";
import { DID_SELECT_DEVICE_COMMAND } from "../../utils/constants";
import { IconRefresh } from "../Common/Icons";
import DeviceConfigurations from "../DeviceConfigurations";

const getDeviceUpdateCommand = (deviceType: DeviceType): Command => {
  let command = DEVICE_COMMANDS_MAP.SSP_VERSION_UPDATE;
  switch (deviceType) {
    case DeviceType.Bolt:
      command = DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE;
      break;
    case DeviceType.SSP:
      command = DEVICE_COMMANDS_MAP.SSP_VERSION_UPDATE;
      break;
    case DeviceType.Lense:
      command = DEVICE_COMMANDS_MAP.LENSE_VERSION_UPDATE;
      break;
    case DeviceType.LenseScreen:
      command = DEVICE_COMMANDS_MAP.LENSE_SCREEN_VERSION_UPDATE;
      break;
  }
  return command;
};

const DeviceCard = ({ devices, brandId, storeName }: { devices: IDevice[]; brandId: string; storeName: string }) => {
  const dispatch = useDispatch();
  const [selectedCommand, setSelectedCommand] = React.useState<Command>(null);

  const onCloseModal = () => {
    setSelectedCommand(null);
    dispatch(resetDeviceCommandState());
  };

  const boltDevice = devices?.find((dev) => dev?.deviceType === DeviceType.Bolt);
  const SSPDevice = devices?.find((dev) => dev?.deviceType === DeviceType.SSP);
  const lenseScreenDevice = devices?.find((dev) => dev?.deviceType === DeviceType.LenseScreen);
  const lenseDevice = devices?.find((dev) => dev?.deviceType === DeviceType?.Lense || !isEmpty(dev?.lensebarConfigurationId));

  const lensebarConfigurationId = devices[0]?.inventories?.find(
    (inventory) => inventory?.lensebarConfigurationId.length > 5
  )?.lensebarConfigurationId;

  const device: IDevice = SSPDevice || lenseDevice || lenseScreenDevice;
  const inventories = device?.inventories.map((inventory) => (
    <div className="devicesByBrand_list-item-details" key={inventory.id}>
      <div className="devicesByBrand_list-item-details-row">
        <span>{inventory.applicationName}</span>
      </div>
      <div className="devicesByBrand_list-item-details-row">
        <span>Version:</span>
        <span>v{inventory.version}</span>
      </div>
      <div className="devicesByBrand_list-item-details-row">
        <span>Last seen: </span>
        <span>{device?.lastOnlineTime}</span>
      </div>
    </div>
  ));
  const [config, setConfig] = React.useState(false);
  const deviceType = getDeviceType(devices);
  return (
    <>
      <div className="devicesByBrand_list deviceCard">
        <div className="devicesByBrand_list-item deviceCard-item">
          <p className="devicesByBrand_list-item-name">{storeName ? storeName : "Country - Boutique Location"}</p>
          <div className="refresh" onClick={() => dispatch(getDevicesForBrand(brandId))}>
            <IconRefresh />
          </div>
          <div className="devicesByBrand_list-item-details">
            <div className="devicesByBrand_list-item-details-row">
              <span>Device type:</span>
              <span>{deviceType}</span>
            </div>
            <div className="devicesByBrand_list-item-details-row">
              <span>Device ID:</span>
              <span>{device?.deviceId}</span>
            </div>
            <div className="devicesByBrand_list-item-details-row">
              <span>Device status:</span>
              <span>{device?.status}</span>
            </div>
            <div className="devicesByBrand_list-item-details-row">
              <span>Last online time:</span>
              <span>{device?.lastOnlineTime}</span>
            </div>
          </div>
          {inventories}
          <div className="deviceCard-footer">
            <Button
              id="deviceMove"
              text="Move Device"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.MOVE_DEVICE.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.MOVE_DEVICE);
              }}
            />
            <Button
              id="deviceLogs"
              text="Logs"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.LOGS.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.LOGS);
              }}
            />

            <Button
              id="deviceReboot"
              text="Reboot"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.REBOOT_SYSTEM.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.REBOOT_SYSTEM);
              }}
            />
            <Button
              id="deviceUpdate"
              text="Update"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: getDeviceUpdateCommand(deviceType).type,
                });
                setSelectedCommand(getDeviceUpdateCommand(deviceType));
              }}
            />

            <Button
              id="deviceBoltUpdate"
              text="Update Bolt"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE);
              }}
            />
            {deviceType === DeviceType.SSP && (
              <Button
                id="DiamondLightUpdate"
                text="Diamond Light"
                onClick={() => {
                  logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                    deviceId: device?.deviceId,
                    command: DEVICE_COMMANDS_MAP.DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE,
                  });
                  setSelectedCommand(DEVICE_COMMANDS_MAP.DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE);
                }}
              />
            )}
            <Button
              id="uninstallDevices"
              text="Uninstall"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.UNINSTALL.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.UNINSTALL);
              }}
            />
            <Button
              id="deviceRemove"
              text="Remove"
              onClick={() => {
                logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                  deviceId: device?.deviceId,
                  command: DEVICE_COMMANDS_MAP.REMOVE_DEVICE.type,
                });
                setSelectedCommand(DEVICE_COMMANDS_MAP.REMOVE_DEVICE);
              }}
            />

            {!!lenseDevice && (
              <Button
                id="scheduler"
                text="Update reboot time"
                onClick={() => {
                  logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                    deviceId: device?.deviceId,
                    command: DEVICE_COMMANDS_MAP.SCHEDULE_RESTART.type,
                  });
                  setSelectedCommand(DEVICE_COMMANDS_MAP.SCHEDULE_RESTART);
                }}
              />
            )}

            {!!lenseDevice && (
              <Button
                id="scheduler"
                text="Update density"
                onClick={() => {
                  logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                    deviceId: device?.deviceId,
                    command: DEVICE_COMMANDS_MAP.UPDATE_DENSITY.type,
                  });
                  setSelectedCommand(DEVICE_COMMANDS_MAP.UPDATE_DENSITY);
                }}
              />
            )}

            {!!lenseDevice && (
              <Button
                id="deviceClearCache"
                text="Clear Cache"
                onClick={() => {
                  logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                    deviceId: device?.deviceId,
                    command: DEVICE_COMMANDS_MAP.CLEAR_IMAGE_CACHE.type,
                  });
                  setSelectedCommand(DEVICE_COMMANDS_MAP.CLEAR_IMAGE_CACHE);
                }}
              />
            )}
            {!!lenseDevice && (
              <>
                <Button
                  id="deviceUpdateProducts"
                  text="Update Products"
                  onClick={() => {
                    logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                      deviceId: device?.deviceId,
                      command: DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS.type,
                    });
                    setSelectedCommand(DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS);
                  }}
                />
                <Button
                  id="deviceUpdateAll"
                  text="Update All Data"
                  onClick={() => {
                    logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                      deviceId: device?.deviceId,
                      command: DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA.type,
                    });
                    setSelectedCommand(DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA);
                  }}
                />
              </>
            )}
            {lensebarConfigurationId && (
              <Button
                id="Configuratio"
                text="Configuration"
                onClick={() => {
                  logEvent(DID_SELECT_DEVICE_COMMAND, DID_SELECT_DEVICE_COMMAND, {
                    deviceId: device?.deviceId,
                    command: "Configuration",
                  });
                  setConfig(true);
                }}
              />
            )}
          </div>
        </div>
      </div>
      <DeviceCommandModal
        command={selectedCommand}
        isOpen={selectedCommand !== null}
        onClose={onCloseModal}
        device={device}
        bolt={boltDevice}
      />
      {config && (
        <DeviceConfigurations
          isOpen={config}
          onClose={() => setConfig(false)}
          configurationId={lensebarConfigurationId}
          device={device}
        />
      )}
      <style>
        {`
          .refresh {
            cursor: pointer;
            position: absolute;
            top: 5px;
            right: 15px;
            transform: rotate(360deg);
            transition: transform 0.5s;
            width:40px;
            height: auto;
            text-align: center;
          }
          .refresh:active {
            transform: rotate(0deg);
            transition:  0s;
          }
          .refresh:hover{
            background-color:#ffffff11;
            border-radius: 20%;
          }
        `}
      </style>
    </>
  );
};

export default DeviceCard;
