import { Control, Field } from "rbx";
import React from "react";
import { ConfigSetting, IRack } from "../../@types";
import SelectInput from "../Common/SelectInput";
import TextInput from "../Common/TextInput";

const InteractiveDisplaySetting = ({
  rack,
  handleLayout,
}: {
  rack?: IRack;
  handleLayout?: (type: string, value: string) => void;
}) => {
  const themeType = [
    { value: "empty", text: "" },
    { value: "DARK", text: "DARK" },
    { value: "LIGHT", text: "LIGHT" },
  ];
  const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const inputValue = e?.target?.value;
    const inputFieldName = e?.target?.name;
    handleLayout(inputFieldName, inputValue);
  };
  const handleOnChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFieldName = e?.target?.name;
    const inputValue = e?.target?.value;
    handleLayout(inputFieldName, inputValue);
  };
  return (
    <div className="setting-container">
      <div className="title-setting">Interactive Display Setting:</div>
      <Field>
        <label className="input-label">Theme</label>
        <Control>
          <SelectInput
            name={ConfigSetting.colourScheme}
            value={`${rack?.colourScheme}` || ""}
            options={themeType}
            onChange={handleOnSelectChange}
            disabled={!rack}
          />
        </Control>
      </Field>
      <Field>
        <label className="input-label">Idle Time</label>
        <Control>
          <TextInput
            id={ConfigSetting.entertainmentModeIdleTime}
            name={ConfigSetting.entertainmentModeIdleTime}
            placeholder="Idle time"
            type="number"
            value={`${rack?.entertainmentModeIdleTime}`}
            onChange={handleOnChangeText}
            disabledEdit={!rack}
          />
        </Control>
      </Field>
      <Field>
        <label className="input-label">X Axis</label>
        <Control>
          <TextInput
            id={ConfigSetting.slots_x_axis}
            name={ConfigSetting.slots_x_axis}
            placeholder="X Axis"
            type="number"
            value={`${rack?.layout.slots_x_axis}`}
            onChange={handleOnChangeText}
            disabledEdit={!rack}
          />
        </Control>
      </Field>
      <Field>
        <label className="input-label">Y Axis</label>
        <Control>
          <TextInput
            id={ConfigSetting.slots_y_axis}
            name={ConfigSetting.slots_y_axis}
            placeholder="Y Axis"
            type="number"
            value={`${rack?.layout.slots_y_axis}`}
            onChange={handleOnChangeText}
            disabledEdit={!rack}
          />
        </Control>
      </Field>
      <style>{`
          .setting-container{
            display:flex;
            margin: 0 0 10px 0;
          }
          .title-setting{
            padding-right:10px;
            padding-top:30px;
          }
         
          .field{
            width: 120px;
            margin: 0px 5px;
          }
        `}</style>
    </div>
  );
};

export default InteractiveDisplaySetting;
