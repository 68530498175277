import classNames from "classnames";
import { Modal as RbxModal } from "rbx";
import { IconClose } from "./Icons";
import React from "react";
import { logEvent } from "../../analytics";
import { DID_CLOSE_MODAL } from "../../utils/constants";

const Modal = ({
  title,
  isOpen,
  onClose,
  children,
  showClose,
  className,
  disableClose,
}: {
  isOpen: boolean;
  onClose: () => void;
  children: React.ReactNode;
  showClose?: boolean;
  title?: string;
  className?: string;
  disableClose?: boolean;
}) => {
  return (
    <div
      className={classNames(`custom-modal ${className}`, {
        show: isOpen,
      })}
    >
      <RbxModal.Background />
      <RbxModal.Content>
        {showClose && (
          <div
            className="close"
            onClick={() => {
              if (!disableClose) {
                logEvent(DID_CLOSE_MODAL, DID_CLOSE_MODAL, {
                  modal: title,
                });
                onClose();
              }
            }}
          >
            <IconClose color={`${disableClose ? "#fff5" : "#fff"}`} />
          </div>
        )}
        {title && <h1 className="title">{title}</h1>}
        {children}
      </RbxModal.Content>
    </div>
  );
};

export default Modal;
