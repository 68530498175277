import { apigClient } from "../aws/apigClient";
import { isNumber } from "lodash";
import { AnalyticEvent } from "../../analytics";

const isEventValid = (event: AnalyticEvent) => {
  if (!isNumber(event.event_timestamp)) {
    return false;
  }
  if (JSON.stringify(event.payload || {}).includes("<script")) {
    return false;
  }
  return true;
};

export const sendCustomAnalytics = async (events: AnalyticEvent[]) => {
  if (!Array.isArray(events))
    return Promise.reject({
      responseCode: 400,
      message: "Analytics events payload is invalid",
    });
  const errors = [];

  events.forEach((event) => {
    if (!isEventValid(event)) {
      errors.push(event);
    }
  });
  if (errors.length) {
    return Promise.reject({
      responseCode: 400,
      message: "Analytics events payload is invalid",
    });
  } else {
    const client = await apigClient();
    const path = "/customAnalyticsEvents/v1/";
    const result = await client.invokeApi({}, path, "POST", {}, events);
    return result.data;
  }
};
