import { Hero } from "rbx";
import React from "react";
import { Redirect } from "react-router-dom";
import Header from "../Common/Header";
import Button from "../Common/Button";
import { Container } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { allBrands } from "../../config";
import { useDispatch } from "react-redux";
import HubUsersList from "./HubUsersList";
import AddHubUser from "./AddHubUser";
import { isMobileOnly } from "react-device-detect";
import { logEvent } from "../../analytics";
import { actionGetHubUsersForBrand, actionResetHubUser } from "../../redux/actions/hubUsers";
import { DID_CLICK_ADD_HUB_USER } from "../../utils/constants";
import { getStoresForBrand } from "../../redux/actions/stores";
const HubUserByBrand = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const brand = allBrands?.find((brand) => brand?.id === id);
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);
  const [selectedUser, setSelectedUser] = React.useState<string>(null);
  const onClosePopUp = () => {
    setShowAddForm(false);
    setSelectedUser(null);
  };
  const onSelectUser = (id: string) => {
    dispatch(actionResetHubUser());
    setSelectedUser(id);
  };

  const onBackCb = () => {
    setSelectedUser(null);
  };
  React.useEffect(() => {
    if (brand) {
      // dispatch(actionGetHubUsersForBrand(brand?.id));
      dispatch(actionGetHubUsersForBrand(brand?.id));
      dispatch(getStoresForBrand(brand?.id));
    }
  }, [brand, dispatch]);

  if (!brand || !isMobileOnly) {
    return <Redirect to="/dashboard/hubusers" />;
  }

  return (
    <>
      <Hero className="devices devicesByBrand" size="fullheight">
        <Hero.Body>
          <Container fluid="sm">
            <Header onBack={selectedUser ? onBackCb : undefined} />
            <div className="devices_main">
              <h1 className="devices_main-heading">{brand?.name}</h1>
              {!selectedUser && (
                <Button
                  id="createNewHubUser"
                  text="+  Add Hub User"
                  onClick={() => {
                    logEvent(DID_CLICK_ADD_HUB_USER);
                    dispatch(actionResetHubUser());
                    setShowAddForm(true);
                  }}
                />
              )}

              <HubUsersList
                brandId={brand?.id}
                setSelectedUser={onSelectUser}
              />
            </div>
          </Container>
        </Hero.Body>
      </Hero>
      <AddHubUser
        brandId={brand?.id}
        isOpen={showAddForm || selectedUser !== null}
        onClose={() => onClosePopUp()}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default HubUserByBrand;
