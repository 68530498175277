import { S3 } from "aws-sdk";
import { IVersion } from "../../@types";
import { getS3Instance } from "../aws/apigClient";
import { hasValidCredentials } from "../login";

// export const listVersion = async (path: string) => {
//   if (!hasValidCredentials()) return [];
//   const s3 = await getS3Instance();
//   const response = s3
//     ?.listObjectsV2({
//       Bucket: "inspify-lense",
//       Prefix: path,
//       MaxKeys: 20,
//     })
//     .promise();
//   const reponse = await ((await response)?.$response?.data as any);
//   console.log("debug", reponse);
//   const result = reponse.Contents;
//   return !result ? [] : result;
// };

//recursion to get all versions
export const listVersion = async (path: string) => {
  if (!hasValidCredentials()) return [];
  const s3 = await getS3Instance();
  return new Promise((resolve, reject) => {
    listAllKeys({
      Bucket: "storiez-lense",
      Prefix: path,
    });
    const allKeys = [];
    function listAllKeys(params: S3.ListObjectsRequest) {
      s3.listObjectsV2(params, function (err, data) {
        if (err) return reject(err);
        const contents = data.Contents;
        allKeys.push(...contents);
        if (!data.IsTruncated) return resolve(allKeys);
        const newParams = {
          ...params,
          ContinuationToken: data.NextContinuationToken,
        };
        console.log("debug:: get more list", newParams);
        listAllKeys(newParams);
      });
    }
  });
};
export const s3RoWPackageUrl =
  "https://storiez-lense.s3.ap-southeast-1.amazonaws.com/";
export const s3CnPackageUrl =
  "https://storiez-lense.s3.ap-southeast-1.amazonaws.com/";
export const getLenseBarVersions = async () =>
  (await listVersion("installation/lense-bar/")) as Array<{
    Key: string;
    LastModified: any;
  }>;

export const getLenseBoltVersions = async () =>
  (await listVersion("installation/lense-admin/")) as Array<{
    Key: string;
    LastModified: any;
  }>;

export const getSSPVersions = async () =>
  (await listVersion("installation/streaming-studio/")) as Array<{
    Key: string;
    LastModified: any;
  }>;
export const getDiamondLightVersions = async () =>
  (await listVersion("installation/diamond-light-controller/")) as Array<{
    Key: string;
    LastModified: any;
  }>;

export const getLenseScreenVersions = async () =>
  (await listVersion("installation/lense-screen/")) as Array<{
    Key: string;
    LastModified: any;
  }>;
const getS3FilenameFromKey = (key: string, path: string): string =>
  key.replace(path, "").split("_")[0].concat("_");

export const extractVersions = (
  keys: Array<{ Key: string }>,
  path: string,
  prefixes: string[]
): IVersion[] => {
  return keys
    .filter((k) => prefixes.includes(getS3FilenameFromKey(k.Key, path)))
    .sort((a: any, b: any) =>
      a.LastModified < b.LastModified
        ? 1
        : a.LastModified === b.LastModified
        ? 0
        : -1
    )
    .splice(0, 100)
    .map((item: any) => ({
      name: (item.Key as string).replace(path, ""),
      version: (item.Key as string)
        .split(getS3FilenameFromKey(item.Key, path))[1]
        .replace(".apk", ""),
      url: s3RoWPackageUrl + item.Key,
      chinaUrl: s3CnPackageUrl + item.Key,
    }));
};

export const extractDLVersions = (
  keys: Array<{ Key: string }>,
  path: string,
  prefixes: string[]
): IVersion[] => {
  return keys
    .sort((a: any, b: any) =>
      a.LastModified < b.LastModified
        ? 1
        : a.LastModified === b.LastModified
        ? 0
        : -1
    )
    .map((item: any) => ({
      name: (item.Key as string).replace(path, ""),
      version: (item.Key as string)
        .split(getS3FilenameFromKey(item.Key, path))[1]
        .replace(".bin", ""),
      url: s3RoWPackageUrl + item.Key,
      chinaUrl: s3CnPackageUrl + item.Key,
    }));
};
