import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { IconBack } from "./Icons";
import { signOut } from "../../services/login";
import {
  DID_CLICK_BACK,
  DID_CLICK_LOGOUT,
  IDENTITY_ID,
} from "../../utils/constants";
import { logEvent } from "../../analytics";

const Header = ({ onBack }: { onBack?: () => void }) => {
  const history = useHistory();
  return (
    <div className="header">
      <div
        className="back"
        onClick={() => {
          logEvent(DID_CLICK_BACK);
          if (onBack) {
            onBack();
            return;
          }
          history?.goBack();
        }}
      >
        <IconBack />
      </div>
      <Dropdown bsPrefix="header-actionDropdown">
        <Dropdown.Toggle bsPrefix="btn toggle-button" id="dropdown-basic">
          <img
            src="https://via.placeholder.com/150"
            alt="avatar"
            className="header-actionDropdown-avatar"
          />
        </Dropdown.Toggle>

        <Dropdown.Menu alignRight>
          <Dropdown.Item
            onClick={() => {
              logEvent(DID_CLICK_LOGOUT, DID_CLICK_LOGOUT, {
                IDENTITY_ID,
              });
              signOut().then(() => {
                localStorage.setItem(IDENTITY_ID, null);
                sessionStorage.setItem("expiry", null);
                history.replace("/");
              });
            }}
          >
            Logout
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
};

export default Header;
