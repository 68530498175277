import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMainState } from "../../@types";
import { getBrandNameById } from "../../config";
import Button from "../Common/Button";
import { IconBack } from "../Common/Icons";
import { logEvent } from "../../analytics";
import {
  DID_CLICK_ADD_HUB_USER,
  DID_CLOSE_SELECT_BRAND,
} from "../../utils/constants";
import HubUsersList from "../HubUsersByBrand/HubUsersList";
import {
  actionGetHubUsersForBrand,
  actionResetHubUser,
} from "../../redux/actions/hubUsers";
import { getStoresForBrand } from "../../redux/actions/stores";
import AddHubUser from "../HubUsersByBrand/AddHubUser";

const BrandHubUserPreview = () => {
  const dispatch = useDispatch();
  const brandId = useSelector((state: IMainState) => state?.hubUsers?.brandId);
  const publishers = useSelector(
    (state: IMainState) => state?.generic?.publishers
  );
  const [selectedUser, setSelectedUser] = React.useState<string>(null);
  const [showAddForm, setShowAddForm] = React.useState<boolean>(false);
  const onClosePopUp = () => {
    setShowAddForm(false);
    setSelectedUser(null);
  };
  const onSelectUser = (id: string) => {
    dispatch(actionResetHubUser());
    setSelectedUser(id);
  };

  React.useEffect(() => {
    if (brandId) {
      setSelectedUser(null);
      dispatch(actionGetHubUsersForBrand(brandId));
      dispatch(getStoresForBrand(brandId));
    }
  }, [brandId, dispatch]);
  if (!brandId) {
    return (
      <div className="devices_preview empty">
        <p className="devices_preview-info">Please select a brand for user</p>
      </div>
    );
  }
  return (
    <>
      <div className="devices_preview devices_main">
        <h1 className="devices_main-heading">
          {getBrandNameById(publishers, brandId)}
        </h1>
        {!selectedUser && (
          <Button
            id="createNewDevice"
            text="+  Add Hub User"
            onClick={() => {
              logEvent(DID_CLICK_ADD_HUB_USER);
              dispatch(actionResetHubUser());
              setShowAddForm(true);
            }}
          />
        )}
        {selectedUser && (
          <div
            className="back"
            onClick={() => {
              logEvent(DID_CLOSE_SELECT_BRAND);
              setSelectedUser(null);
            }}
          >
            <IconBack />
          </div>
        )}
        <HubUsersList brandId={brandId} setSelectedUser={onSelectUser} />
      </div>
      <AddHubUser
        brandId={brandId}
        isOpen={showAddForm || selectedUser !== null}
        onClose={() => onClosePopUp()}
        selectedUser={selectedUser}
      />
    </>
  );
};

export default BrandHubUserPreview;
