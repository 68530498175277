export const atLeastHasNonZeroNumber = (input: string) =>
  input.match(/(0*[1-9]+0*)+/) != null;
export const isValidName = (name: string) =>
  name && name.trim().length > 0 && !/<script/i.test(name);
export const isValidEmail = (email: string) =>
  // eslint-disable-next-line no-useless-escape
  email && /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.trim());
export const isValidPhoneNumber = (number: string) =>
  number &&
  atLeastHasNonZeroNumber(number) &&
  /^\+?([0-9]{2})\)?[-. ]?([0-9]+)[-. ]?([0-9]+)?[-. ]?([0-9]+)?$/.test(
    number.trim()
  );
export const isValidCountryCode = (countryCode: string) =>
  countryCode && /^\+?[0-9]+$/.test(countryCode.trim());

export const isUserPage = () =>
  window.location.hash.replace("#/", "").split("/").indexOf("hubusers") > -1;

export const checkIfNumber = (key: string) => {
  const regex = /^\d+$/;
  return regex.test(key);
};
export const CheckMP4Url = (url: string) => {
  const checkA = url.match(/(https|http):\/\/(.*?)\.(.*?)\.(.*?)(\/|'')/g);
  const checkP = url.match(/(https|http):\/\/(.*?)\.(.*?)(\/|'')/g);
  const mp4 = url.toLowerCase().endsWith(".mp4");
  if ((checkA != null || checkP != null) && mp4) return true;

  return false;
};
