import { IMainState } from "../../@types";

const defaultState: IMainState = {
  login: {
    inProgress: false,
    error: undefined,
    authenticated: false,
  },
  devices: {
    fetching: false,
    error: undefined,
    devices: {},
    isSubmitting: false,
  },
  hubUsers: {
    fetching: false,
    error: undefined,
    isSubmitting: false,
    hubUsers: {},
  },
  stores: {
    fetching: false,
    stores: null,
  },
  generic: {},
};

export default defaultState;
