import defaultState from "../initialState";
import { IAction, IStores } from "../../../@types";
import {
  FETCH_STORES_BY_BRAND_FAILURE,
  FETCH_STORES_BY_BRAND_REQUEST,
  FETCH_STORES_BY_BRAND_SUCCESS,
} from "../../../utils/constants";

const loginReducer = (
  state: IStores = defaultState?.stores,
  action: IAction<any>
) => {
  switch (action.type) {
    case FETCH_STORES_BY_BRAND_REQUEST: {
      return { ...state, fetching: true, error: undefined };
    }
    case FETCH_STORES_BY_BRAND_SUCCESS: {
      const { brandId, stores } = action?.payload;
      return {
        ...state,
        fetching: false,
        error: undefined,
        stores: { ...state.stores, [brandId]: stores },
      };
    }
    case FETCH_STORES_BY_BRAND_FAILURE: {
      return { ...state, fetching: false, error: action.payload };
    }
    default:
      return state;
  }
};

export default loginReducer;
