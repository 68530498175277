import { ICreateUserPayload, IHubUser } from "../../@types";
import { prodBaseUrl } from "../../config";
import { STORE_ID } from "../../utils/constants";
import { apigClient } from "../aws/apigClient";


export const HUB_USER_COMMANDS_MAP = {
  UPDATE_HUB_USER: {
    type: "UPDATE_HUB_USER",
    argumments: [],
  },
  REMOVE_HUB_USER: {
    type: "REMOVE_HUB_USER",
    argumments: [],
  },
};
export const getHubUserByStoreIdService = async (storeId: string) => {
  try {
    const path = `/users/v3/web/byStoreId/${storeId}`;
    const client = await apigClient();
    const usersResponse = await client.invokeApi({}, path, "GET", {});
    return usersResponse.data;
  } catch (error) {
    console.log(error);
  }
};
export const getHubUserByBrandIdService = async (brandId: string) => {
  try {
    const path = `/users/v3/byBrandId/${brandId}`;
    const client = await apigClient(prodBaseUrl);
    const usersResponse = await client.invokeApi({}, path, "GET", {});
    return usersResponse.data;
  } catch (error) {
    console.log(error);
  }
};
export const createNewHubUser = async (_user: IHubUser) => {
  try {
    let storeId = _user.entitlements.filter((entitlement) => {
      return entitlement.entitlementType === STORE_ID;
    });
    const createHubUser: ICreateUserPayload = {
      username: _user.user_name,
      storeId: storeId[0]?.uuid,
      attributes: {
        email: _user.email,
        email_verified: "true",
      },
    };
    const path = `/users/v3/web/createNewUser`;
    const client = await apigClient();
    const createUserResponse = await client.invokeApi(
      {},
      path,
      "POST",
      {},
      createHubUser
    );
    const newInspifyUser: IHubUser = {
      ..._user,
      id: createUserResponse.data.identityId,
    };
    return saveUser(newInspifyUser);
  } catch (error) {
    console.log(error.response.data);
    return Promise.reject(error?.response?.data);
  }
};

export const saveUser = async (user: IHubUser): Promise<any> => {
  const client = await apigClient();
  const path = `/users/v3/web`;
  return await client.invokeApi({}, path, "PUT", {}, user);
};

export const deleteHubUserById = async (userId: string) => {
  const path = `/users/v3/web/deleteById`;
  const client = await apigClient();
  const deleteUserResponse = await client.invokeApi(
    {},
    path,
    'DELETE',
    {},
    userId
  );
  return deleteUserResponse.data;
};
