import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMainState, IDevice } from "../../@types";
import { sendDeviceCommand, resetDeviceCommandState, removeDeviceCommand } from "../../redux/actions/devices";
import { Command, DEVICE_COMMANDS_MAP } from "../../services/device";
import Button from "../Common/Button";
import Modal from "../Common/Modal";
import { DeviceType } from "./AddDeviceModal";
import { toast } from "react-toastify";
import DeviceUpdateModal from "./DeviceUpdateModal";
import { getDeviceType } from "../../utils/device";
import { logEvent } from "../../analytics";
import { DID_EXECUTE_DEVICE_COMMAND, DID_OPEN_DEVICE_GRAFANALINK, DID_OPEN_DEVICE_LOGS } from "../../utils/constants";
import DeviceUninstallModal from "./DeviceUninstallModal";
import DeviceUpdateRebootTime from "./DeviceUpdateRebootTime";
import DevideUpdateDensity from "./DeviceUpdateDensity";
import DeviceUpdateStore from "./DeviceUpdateStore";

enum ModalContentType {
  PROMPT_MODAL_CONTENT = "PROMPT_MODAL_CONTENT",
  UPDATE_MODAL_CONTENT = "UPDATE_MODAL_CONTENT",
  UNINSTALL_MODAL_CONTENT = "UNINSTALL_MODAL_CONTENT",
  UPDATE_REBOOT_TIME = "UPDATE_REBOOT_TIME",
  UPDATE_DENSITY = "UPDATE_DENSITY",
  MOVE_DEVICE = "MOVE_DEVICE",
}

const getDeviceLogSource = (deviceType: string): string => {
  let logSource = "SSP";

  switch (deviceType) {
    case DeviceType.SSP:
      logSource = "SSP";
      break;
    case DeviceType.Lense:
      logSource = "LenseBar";
      break;
    case DeviceType.Bolt:
      logSource = "LenseBolt";
      break;
    case DeviceType.LenseScreen:
      logSource = "LenseScreen";
      break;
    case DeviceType.DiamondLight:
      logSource = "DiamondLight";
      break;
  }

  return logSource;
};

const DeviceCommandModal = ({
  device,
  command,
  isOpen,
  onClose,
  bolt,
}: {
  isOpen: boolean;
  onClose: () => void;
  command: Command;
  device: IDevice;
  bolt: IDevice;
}) => {
  const dispatch = useDispatch();
  const deviceCommandState = useSelector((state: IMainState) => state?.devices?.deviceCommand);

  const isSubmittingCommand = deviceCommandState?.isSubmitting;
  const sendCommandError = deviceCommandState?.error;
  const lastExecutedCommand = deviceCommandState?.executedCommand;

  const getDeviceTypeByCommand = (command: Command): DeviceType => {
    switch (command?.type) {
      case DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE.type:
      case DEVICE_COMMANDS_MAP.SCHEDULE_RESTART.type:
      case DEVICE_COMMANDS_MAP.UPDATE_DENSITY.type:
        return DeviceType.Bolt;
      case DEVICE_COMMANDS_MAP.DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE.type:
        return DeviceType.DiamondLight;
      case DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA.type:
      case DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS.type:
      case DEVICE_COMMANDS_MAP.LENSE_VERSION_UPDATE.type:
        return DeviceType.Lense;
      case DEVICE_COMMANDS_MAP.LENSE_SCREEN_VERSION_UPDATE.type:
        return DeviceType.LenseScreen;

      default:
        return undefined;
    }
  };
  const type = getDeviceTypeByCommand(command);

  let title = null;
  let modalBodyDescription = "";
  let modalContentType: ModalContentType = null;

  switch (command?.type) {
    case DEVICE_COMMANDS_MAP.CLEAR_IMAGE_CACHE.type:
      title = "Clear Cache";
      modalBodyDescription = "Are you sure you want to permanently clear all cache?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;

    case DEVICE_COMMANDS_MAP.LENSE_SCREEN_VERSION_UPDATE.type:
      title = "Update Lense Screen";
      modalContentType = ModalContentType.UPDATE_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.LENSE_VERSION_UPDATE.type:
      title = "Update Lense";
      modalContentType = ModalContentType.UPDATE_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE.type:
      title = "Update Bolt";
      modalContentType = ModalContentType.UPDATE_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.SSP_VERSION_UPDATE.type:
      title = "Update SSP";
      modalContentType = ModalContentType.UPDATE_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.REMOVE_DEVICE.type:
      title = "Remove device";
      modalBodyDescription = "Are you sure you want to remove device?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.LOGS.type:
      title = "Logs";
      modalBodyDescription = "Are you sure you want to open Logs?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.GRAFANA.type:
      title = "Grafana";
      modalBodyDescription = "Are you sure you want to open Grafana dashboard?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.REBOOT_SYSTEM.type:
      title = "Reboot";
      modalBodyDescription = "Are you sure you want to reboot this device?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE.type:
      title = "Update Diamond Light";
      modalContentType = ModalContentType.UPDATE_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.UNINSTALL.type:
      title = "Uninstall";
      modalBodyDescription = "Enter package name to uninstall";
      modalContentType = ModalContentType.UNINSTALL_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.SCHEDULE_RESTART.type:
      title = "Schedule Restart Bolt";
      modalContentType = ModalContentType.UPDATE_REBOOT_TIME;
      break;
    case DEVICE_COMMANDS_MAP.UPDATE_DENSITY.type:
      title = "Update Density";
      modalContentType = ModalContentType.UPDATE_DENSITY;
      break;
    case DEVICE_COMMANDS_MAP.MOVE_DEVICE.type:
      title = "Move Device";
      modalBodyDescription = "Select the store to move the device to";
      modalContentType = ModalContentType.MOVE_DEVICE;
      break;
    case DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS.type:
      title = "Update Products";
      modalBodyDescription = "Are you sure you want to update products?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
    case DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA.type:
      title = "Update All Data";
      modalBodyDescription = "Are you sure you want to update all data?";
      modalContentType = ModalContentType.PROMPT_MODAL_CONTENT;
      break;
  }

  const onConfirmCommand = () => {
    if (command?.type === DEVICE_COMMANDS_MAP.LOGS.type) {
      const link = `https://app.scalyr.com/events?logSource=${getDeviceLogSource(device?.deviceType)}&log=${device?.deviceId}`;
      logEvent(DID_OPEN_DEVICE_LOGS, DID_OPEN_DEVICE_LOGS, {
        device: device?.deviceId,
        link,
      });
      window.open(link, "_blank").focus();
      onClose();
      return;
    }
    if (command?.type === DEVICE_COMMANDS_MAP.GRAFANA.type) {
      const link = device?.grafanaLink;
      logEvent(DID_OPEN_DEVICE_GRAFANALINK, DID_OPEN_DEVICE_GRAFANALINK, {
        device: device?.deviceId,
        link,
      });
      window.open(link, "_blank").focus();
      onClose();
      return;
    }
    logEvent(DID_EXECUTE_DEVICE_COMMAND, DID_EXECUTE_DEVICE_COMMAND, {
      device: device?.deviceId,
      command,
    });
    switch (command.type) {
      case DEVICE_COMMANDS_MAP.REMOVE_DEVICE.type:
        dispatch(removeDeviceCommand(device?.deviceId, device?.storeId, command));
        break;
      case DEVICE_COMMANDS_MAP.UPDATE_PRODUCTS.type:
      case DEVICE_COMMANDS_MAP.UPDATE_ALL_DATA.type:
        console.log("send device command", command);
        dispatch(sendDeviceCommand(device?.deviceId, DeviceType.Lense, command));
        break;
      default:
        dispatch(sendDeviceCommand(device?.deviceId, getDeviceType([device]), command));
        break;
    }
  };

  const showClose =
    command?.type === DEVICE_COMMANDS_MAP.LENSE_VERSION_UPDATE.type ||
    command?.type === DEVICE_COMMANDS_MAP.LENSE_ADMIN_VERSION_UPDATE.type ||
    command?.type === DEVICE_COMMANDS_MAP.SSP_VERSION_UPDATE.type ||
    command?.type === DEVICE_COMMANDS_MAP.LENSE_SCREEN_VERSION_UPDATE.type ||
    command?.type === DEVICE_COMMANDS_MAP.DIAMOND_LIGHT_CONTROLLER_VERSION_UPDATE.type;

  const promptModalContent = () => {
    return (
      <div className="prompt-modal-content">
        <p>{modalBodyDescription}</p>
        <div className="prompt-modal-content-footer">
          <Button id="Back" text="Back" border onClick={onClose} className="prompt-modal-content-footer-btn" />
          <Button
            id="Proceed"
            text="Proceed"
            isLoading={isSubmittingCommand}
            onClick={onConfirmCommand}
            className="prompt-modal-content-footer-btn"
          />
        </div>
      </div>
    );
  };

  React.useEffect(() => {
    if (sendCommandError || lastExecutedCommand) {
      onClose();
    }
  }, [sendCommandError, lastExecutedCommand, onClose]);

  React.useEffect(() => {
    if (lastExecutedCommand) {
      toast.success("Command sent successfully. ", {
        autoClose: 5000,
      });
    }
  }, [lastExecutedCommand]);

  React.useEffect(() => {
    if (sendCommandError)
      toast.error(sendCommandError?.message ? sendCommandError?.message : "Unable to send command", {
        autoClose: 5000,
      });
  }, [sendCommandError]);
  React.useEffect(() => {
    return () => {
      dispatch(resetDeviceCommandState());
    };
  }, [dispatch]);

  return (
    <Modal isOpen={isOpen} onClose={onClose} title={title} showClose={showClose} className="DeviceCommandModal">
      {modalContentType === ModalContentType.PROMPT_MODAL_CONTENT && promptModalContent()}
      {modalContentType === ModalContentType.UNINSTALL_MODAL_CONTENT && (
        <DeviceUninstallModal device={device} type={type} onClose={onClose} command={command} />
      )}
      {modalContentType === ModalContentType.UPDATE_MODAL_CONTENT && (
        <DeviceUpdateModal device={device} type={type} onClose={onClose} command={command} />
      )}
      {modalContentType === ModalContentType.UPDATE_REBOOT_TIME && (
        <DeviceUpdateRebootTime device={device} type={type} onClose={onClose} command={command} />
      )}
      {modalContentType === ModalContentType.UPDATE_DENSITY && (
        <DevideUpdateDensity device={device} type={type} onClose={onClose} command={command} />
      )}
      {modalContentType === ModalContentType.MOVE_DEVICE && <DeviceUpdateStore device={device} onClose={onClose} />}
    </Modal>
  );
};

export default DeviceCommandModal;
