import * as React from "react";

export const IconUser = (props) => {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.055 11.554h9.89A5.01 5.01 0 006 7.294a5.01 5.01 0 00-4.945 4.26zM0 12.294c0-3.31 2.692-6 6-6s6 2.69 6 6c0 .143-.117.26-.26.26H.26a.261.261 0 01-.26-.26zM6 4.74A1.87 1.87 0 106 1a1.87 1.87 0 000 3.74zm2.87-1.87a2.87 2.87 0 11-5.74 0 2.87 2.87 0 015.74 0z"
        fill="#8F6599"
      />
    </svg>
  );
};

export const IconPassword = (props) => {
  return (
    <svg
      width={9}
      height={17}
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M.014 4.986A4.49 4.49 0 014.5.5a4.49 4.49 0 014.486 4.486A4.486 4.486 0 016.762 8.86v.45a.94.94 0 01-.177.548l-.162.225.156.213a.94.94 0 01.183.556v.873a.94.94 0 01-.224.608l-.106.125.148.202a.94.94 0 01.182.555v.88a.94.94 0 01-.242.63L5.197 16.19a.94.94 0 01-1.394 0L2.48 14.725a.94.94 0 01-.242-.63V8.86A4.486 4.486 0 01.014 4.986zM4.5 1.5a3.49 3.49 0 00-3.486 3.486A3.483 3.483 0 002.96 8.113l.279.137v5.822L4.5 15.47l1.262-1.398v-.836l-.603-.823.603-.71v-.83l-.575-.783.575-.799v-1.04l.279-.138a3.483 3.483 0 001.945-3.127A3.49 3.49 0 004.5 1.5zm1.273 7.775zM4.477 4.236a.592.592 0 000 1.182.592.592 0 000-1.181zm-1.59.591a1.592 1.592 0 013.18 0c0 .877-.712 1.59-1.59 1.59-.877 0-1.59-.713-1.59-1.59z"
        fill="#8F6599"
      />
    </svg>
  );
};

export const IconArrowFill = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <circle cx={7.96632} cy={7.96718} r={7.15199} fill="#8F6599" />
      <path
        d="M.881 7.467a.5.5 0 000 1v-1zm10.963.854a.5.5 0 000-.707L8.66 4.432a.5.5 0 00-.707.707l2.829 2.828-2.829 2.829a.5.5 0 10.708.707l3.181-3.182zM.88 8.467H11.49v-1H.88v1z"
        fill="#1D2A31"
      />
    </svg>
  );
};

export const IconBack = (props) => {
  return (
    <svg
      width={15}
      height={12}
      viewBox="0 0 15 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g clipPath="url(#clip0)">
        <path
          d="M3.85 6.428l6.296 5.395a.795.795 0 001.001 0 .546.546 0 000-.856L5.351 6l5.795-4.966a.546.546 0 000-.857.795.795 0 00-1 0L3.85 5.572a.551.551 0 000 .856z"
          fill="#fff"
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" transform="translate(.5)" d="M0 0H14V12H0z" />
        </clipPath>
      </defs>
    </svg>
  );
};

export const IconSearch = (props) => {
  return (
    <svg
      width={12}
      height={11}
      viewBox="0 0 12 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M8.572 7.1c.542-.739.865-1.647.865-2.631A4.474 4.474 0 004.97 0 4.474 4.474 0 00.5 4.469a4.474 4.474 0 004.469 4.468A4.44 4.44 0 007.6 8.072L10.528 11l.972-.972L8.572 7.1zm-3.603.462A3.097 3.097 0 011.875 4.47a3.097 3.097 0 013.094-3.094 3.097 3.097 0 013.093 3.094A3.097 3.097 0 014.97 7.562z"
        fill="#8F6599"
      />
    </svg>
  );
};

export const IconRefresh = (props) => {
  return (
    <svg
      width={30}
      height={30}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M 15 3 C 12.031398 3 9.3028202 4.0834384 7.2070312 5.875 A 1.0001 1.0001 0 1 0 8.5058594 7.3945312 C 10.25407 5.9000929 12.516602 5 15 5 C 20.19656 5 24.450989 8.9379267 24.951172 14 L 22 14 L 26 20 L 30 14 L 26.949219 14 C 26.437925 7.8516588 21.277839 3 15 3 z M 4 10 L 0 16 L 3.0507812 16 C 3.562075 22.148341 8.7221607 27 15 27 C 17.968602 27 20.69718 25.916562 22.792969 24.125 A 1.0001 1.0001 0 1 0 21.494141 22.605469 C 19.74593 24.099907 17.483398 25 15 25 C 9.80344 25 5.5490109 21.062074 5.0488281 16 L 8 16 L 4 10 z"
        fill="#8F6599"
      />
    </svg>
  );
};

export const IconClose = (props) => {
  return (
    <svg
      width={20}
      height={21}
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 19.417a8.75 8.75 0 100-17.5 8.75 8.75 0 000 17.5zm10-8.75c0 5.523-4.477 10-10 10s-10-4.477-10-10 4.477-10 10-10 10 4.477 10 10z"
        fill={props?.color || "#fff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 9.69l3.735 4.483a.625.625 0 11-.96.8L10 11.643l-2.775 3.33a.625.625 0 11-.96-.8L10 9.691z"
        fill={props?.color || "#fff"}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 11.643L6.265 7.161a.625.625 0 01.96-.8L10 9.69l2.775-3.33a.625.625 0 11.96.8L10 11.643z"
        fill={props?.color || "#fff"}
      />
    </svg>
  );
};

export const IconUpdateVersion = (props) => {
  return (
    <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <ellipse
        cx={8.16176}
        cy={8.15202}
        rx={6.738}
        ry={7.15199}
        transform="rotate(-90 8.162 8.152)"
        stroke="#8F6599"
      />
      <path
        d="M7.662 14.827a.5.5 0 001 0h-1zm.853-10.348a.5.5 0 00-.707 0L4.626 7.66a.5.5 0 00.707.707l2.829-2.829 2.828 2.829a.5.5 0 10.707-.707L8.515 4.479zm.147 10.348V4.832h-1v9.995h1z"
        fill="#8F6599"
      />
    </svg>
  );
};
