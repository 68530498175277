import {
  IProduct,
  IProductFilters,
  IRack,
  IRackPayload,
  IRackProductSlot,
  ISearchResult,
} from "../../@types";
import { apigClient } from "../aws/apigClient";
import { orderBy, flatten } from "lodash";
import { clock, toS3Url } from "../../utils/device";
export const getFilterQueryString = (
  filters: IProductFilters | undefined
): string => {
  if (!filters) return "";
  return Object.keys(filters)
    .map((key) => {
      if (
        filters[key as keyof IProductFilters] &&
        (filters[key as keyof IProductFilters] as Array<any>).length > 0
      ) {
        return `(or ${(filters[key as keyof IProductFilters] as any)
          .map((v: string) => `${key}: '${v}'`)
          .join(" ")})`;
      }
      return "";
    })
    .join(" ");
};

export const searchForProducts = async ({
  keywords,
  brandId = undefined,
  filters = undefined,
  start = 0,
  size = 20,
}: {
  keywords: string;
  brandId?: string;
  filters?: IProductFilters;
  start?: number;
  size?: number;
}): Promise<ISearchResult<IProduct>> => {
  const client = await apigClient();
  const path = `/productsearch/v5`;
  const searchAttributes = {
    keywords,
    startIndex: start,
    size,
    queryString: brandId
      ? `(and brand: '${brandId}' ${getFilterQueryString(filters)})`
      : "",
  };
  const productSearchAttributes = {
    searchAttributes,
    flattenResult: false,
  };
  const productsResponse = await client.invokeApi(
    {},
    path,
    "POST",
    {},
    productSearchAttributes
  );
  const results: IProduct[] = flatten(
    productsResponse.data.productOfferings
  ).map(mapProductPayloadToProduct);
  const { totalCount, lastIndex } = productsResponse.data;
  return { results, totalCount, lastIndex };
};
export const loadProducts = async (ids: string[]): Promise<IProduct[]> => {
  const client = await apigClient();
  return Promise.all(
    ids.map(async (id) => {
      const path = `/products/v2/${id}`;
      const productResult = await client.invokeApi({}, path, "GET", {});
      if (productResult.data && productResult.data.length > 0) {
        return mapProductPayloadToProduct(productResult.data[0]);
      }
      return;
    })
  ).then((data: (IProduct | undefined)[]) => {
    return data.filter((datum) => !!datum) as IProduct[];
  });
};
export const saveRack = async (rack: IRack) => {
  const client = await apigClient();
  const path = `/lenseBarConfigurations/v1`;
  const body = mapRackToRackRequest(rack);
  const result = await client.invokeApi({}, path, "PUT", {}, body);

  return mapRackPayloadToRack(result.data);
};

export const loadRackById = async (id: string) => {
  const client = await apigClient();
  const path = `/lenseBarConfigurations/v2/${id}`;
  const method = "GET";
  const result = await client.invokeApi({}, path, method, {});
  const value = mapRackPayloadToRack(result.data);
  return value;
};
export const mapRackToRackRequest = (payload: IRack): IRackPayload => {
  const {
    id,
    name,
    description,
    storeId,
    productSlots,
    colourScheme,
    entertainmentModeIdleTime,
    layout,
    createdAt,
    createdBy,
    modifiedBy,
    beacon_minor,
    beacon_uuid,
    showcaseVideos,
  } = payload;

  const products: IRackProductSlot[] =
    (productSlots &&
      productSlots.map((product: IProduct, index: number) => ({
        productId: product.id,
        slot: index + 1,
      }))) ||
    [];

  return {
    id: id || " ",
    name,
    description,
    storeId,
    entertainmentModeIdleTime,
    layout,
    productSlots: products,
    colourScheme,
    modifiedBy,
    modifiedAt: clock.now().toISOString(),
    createdAt,
    createdBy,
    beacon_minor,
    beacon_uuid,
    showcaseVideos,
  };
};
export const mapRackPayloadToRack = async (
  payload: IRackPayload
): Promise<IRack> => {
  const {
    id,
    name,
    description,
    storeId,
    entertainmentModeIdleTime,
    layout,
    productSlots,
    colourScheme,
    createdBy,
    createdAt,
    modifiedBy,
    modifiedAt,
    beacon_minor,
    beacon_uuid,
    showcaseVideos,
  } = payload;

  const productSlotsSorted = orderBy(productSlots, ["slot"], ["asc"]);

  const productsIdList: string[] = productSlotsSorted.map(
    (p: IRackProductSlot) => p.productId
  );

  const products = await loadProducts(productsIdList);

  return {
    id,
    name,
    description,
    storeId,
    createdBy,
    createdAt,
    modifiedBy,
    modifiedAt,
    productSlots: products,
    colourScheme,
    entertainmentModeIdleTime,
    layout,
    beacon_minor,
    beacon_uuid,
    mosaicImage: `inspify-products/mosaicImages/${id}_mosaic.png`,
    showcaseVideos,
  };
};
const getPreviewImageUrl = (
  imageUrl: string,
  product_images: string[]
): string | undefined => {
  if (imageUrl) {
    return toS3Url(imageUrl);
  }
  if (product_images && product_images.length > 0) {
    return toS3Url(product_images[0]);
  }
  return;
};
export const mapProductPayloadToProduct = (payload): IProduct => {
  const {
    id,
    product_family,
    brand,
    brand_name,
    colortext,
    gender,
    material,
    sales_price_sg,
    currency,
    offering_currency,
    offering_price,
    description,
    long_description,
    model_code,
    model_group,
    model_name,
    type,
    imageUrl,
    product_images,
    product_images_hires,
    productColorImageURL,
    officialSalesPrices,
    watchSpecifications,
    jewelrySpecifications,
    tag,
    status,
    editions,
    lookBook,
    timestamp,
  } = payload;

  return {
    id,
    productFamily: product_family,
    brandId: brand,
    brandName: brand_name,
    colorText: colortext,
    gender,
    material,
    salesPriceSG: sales_price_sg,
    currency,
    offeringCurrency: offering_currency,
    offeringPrice: offering_price,
    description,
    longDescription: long_description,
    modelCode: model_code,
    modelGroup: model_group,
    modelName: model_name,
    type,
    previewImageUrl: getPreviewImageUrl(imageUrl, product_images),
    imageUrls: product_images,
    imageHiResUrls: product_images_hires,
    productColorImageURL: productColorImageURL,
    officialSalesPrices,
    watchSpecifications,
    jewelrySpecification: jewelrySpecifications,
    tag,
    status,
    editions,
    lookBook,
    timestamp,

    originalImageUrlsLength: (product_images && product_images.length) || 0,
  };
};
