export const DUMMY_STORE_ID = "5de75140-048d-40ef-940a-c0b66fd32e3a";//INSPIFY HQ
export const DUMMY_BRAND_ID = "1bc913e3-faf3-47c3-a097-074905a292fd";//INSPIFY

export const STATUS_OK = "OK";
export const STORE_ID = "STORE_ID";
export const BRAND_ID = "BRAND_ID";
export const USER_ADMIN = "USER_ADMIN";
export const ADD_PUBLISHERS = "ADD_PUBLISHERS";
export const SALES = "SALES";
export const MEET_USER = "MEET_USER";
export const MEET_ADMIN_USER = "MEET_ADMIN_USER";
export const RECORDING_VISIBILITY = "RECORDING_VISIBILITY";
export const IDENTITY_ID = "IDENTITY_ID";
export const ANALYTICS_SOURCE = "INSPIFY-ADMIN";

export const LOGIN_START = "INSPIFY_ADMIN.LOGIN_START";
export const LOGIN_FAILED = "INSPIFY_ADMIN.LOGIN_FAILED";
export const LOGIN_SUCCESSFUL = "INSPIFY_ADMIN.LOGIN_SUCCESSFUL";

export const GET_USER_OBJECT_SUCCESS = "INSPIFY_ADMIN.GET_USER_OBJECT_SUCCESS";
export const DID_CLICK_ADD_HUB_USER = "DID_CLICK_ADD_HUB_USER";
export const SELECT_BRAND_FOR_HUB_USER_PREVIEW =
  "INSPIFY_ADMIN.SELECT_BRAND_FOR_USER_PREVIEW";

export const FETCHING_HUB_USERS_BY_BRAND =
  "INSPIFY_ADMIN.FETCHING_HUB_USERS_BY_BRAND";
export const FAILED_FETCH_HUB_USERS_BY_BRAND =
  "INSPIFY_ADMIN.FAILED_FETCH_HUB_USERS_BY_BRAND";
export const SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND =
  "INSPIFY_ADMIN.SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND";
export const DID_SELECT_HUB_USER = "INSPIFY_ADMIN.DID_SELECT_HUB_USER";

export const ADD_NEW_HUB_USER_REQUEST =
  "INSPIFY_ADMIN.ADD_NEW_HUB_USER_REQUEST";
export const ADD_NEW_HUB_USER_SUCCESS =
  "INSPIFY_ADMIN.ADD_NEW_HUB_USER_SUCCESS";
export const ADD_NEW_HUB_USER_FAILURE =
  "INSPIFY_ADMIN.ADD_NEW_HUB_USER_FAILURE";
export const RESET_NEW_HUB_USER_REQUEST =
  "INSPIFY_ADMIN.RESET_NEW_HUB_USER_REQUEST";
export const DID_SUBMIT_NEW_HUB_USER = "INSPIFY_ADMIN.DID_SUBMIT_NEW_HUB_USER";


export const SEND_HUB_USER_COMMAND_REQUEST = "INSPIFY_ADMIN.SEND_HUB_USER_COMMAND_REQUEST"
export const SEND_HUB_USER_COMMAND_SUCCESS = "INSPIFY_ADMIN.SEND_HUB_USER_COMMAND_SUCCESS"
export const RESET_HUB_USER_COMMAND_STATE = "INSPIFY_ADMIN.RESET_HUB_USER_COMMAND_STATE"
export const SEND_HUB_USER_COMMAND_FAILURE = "INSPIFY_ADMIN.SEND_HUB_USER_COMMAND_FAILURE"
export const DID_EXECUTE_HUB_USER_COMMAND = "DID_EXECUTE_HUB_USER_COMMAND";
export const DID_SELECT_HUB_USER_TO_UPDATE = "DID_SELECT_HUB_USER_TO_UPDATE";

export const FETCHING_DEVICES_BY_BRAND =
  "INSPIFY_ADMIN.FETCHING_DEVICES_BY_BRAND";
export const FAILED_FETCH_DEVICES_BY_BRAND =
  "INSPIFY_ADMIN.FAILED_FETCH_DEVICES_BY_BRAND";
export const SUCCESSFUL_FETCH_DEVICES_BY_BRAND =
  "INSPIFY_ADMIN.SUCCESSFUL_FETCH_DEVICES_BY_BRAND";

export const RESET_NEW_DEVICE_REQUEST =
  "INSPIFY_ADMIN.RESET_NEW_DEVICE_REQUEST";
export const ADD_NEW_DEVICE_REQUEST = "INSPIFY_ADMIN.ADD_NEW_DEVICE_REQUEST";
export const ADD_NEW_DEVICE_SUCCESS = "INSPIFY_ADMIN.ADD_NEW_DEVICE_SUCCESS";
export const ADD_NEW_DEVICE_FAILURE = "INSPIFY_ADMIN.ADD_NEW_DEVICE_FAILURE";

export const FETCH_STORES_BY_BRAND_REQUEST =
  "INSPIFY_ADMIN.FETCH_STORES_BY_BRAND_REQUEST";
export const FETCH_STORES_BY_BRAND_SUCCESS =
  "INSPIFY_ADMIN.FETCH_STORES_BY_BRAND_SUCCESS";
export const FETCH_STORES_BY_BRAND_FAILURE =
  "INSPIFY_ADMIN.FETCH_STORES_BY_BRAND_FAILURE";

export const SEND_DEVICE_COMMAND_REQUEST =
  "INSPIFY_ADMIN.SEND_DEVICE_COMMAND_REQUEST";
export const SEND_DEVICE_COMMAND_SUCCESS =
  "INSPIFY_ADMIN.SEND_DEVICE_COMMAND_SUCCESS";
export const SEND_DEVICE_COMMAND_FAILURE =
  "INSPIFY_ADMIN.SEND_DEVICE_COMMAND_FAILURE";
export const RESET_DEVICE_COMMAND_STATE =
  "INSPIFY_ADMIN.RESET_DEVICE_COMMAND_STATE";

export const SAVE_VERSIONS_TO_REDUX = "INSPIFY_ADMIN.SAVE_VERSIONS_TO_REDUX";
export const SELECT_BRAND_FOR_DEVICES_PREVIEW =
  "INSPIFY_ADMIN.SELECT_BRAND_FOR_DEVICES_PREVIEW";

export const DID_VIEW_PAGE = "DID_VIEW_PAGE";
export const DID_CLICK_ADD_DEVICE = "DID_CLICK_ADD_DEVICE";
export const DID_CLICK_BACK = "DID_CLICK_BACK";
export const DID_CLICK_LOGOUT = "DID_CLICK_LOGOUT";
export const DID_REFRESH_LIST_APK = "DID_REFRESH_LIST_APK";
export const DID_CLOSE_MODAL = "DID_CLOSE_MODAL";
export const DID_SELECT_BRAND = "DID_SELECT_BRAND";
export const DID_CLOSE_SELECT_BRAND = "DID_CLOSE_SELECT_BRAND";
export const DID_CLICK_ADD_NEW_DEVICE = "DID_CLICK_ADD_NEW_DEVICE";
export const DID_SUBMIT_NEW_DEVICE = "DID_SUBMIT_NEW_DEVICE";
export const DID_SELECT_DEVICE_COMMAND = "DID_SELECT_DEVICE_COMMAND";
export const DID_EXECUTE_DEVICE_COMMAND = "DID_EXECUTE_DEVICE_COMMAND";
export const DID_OPEN_DEVICE_LOGS = "DID_OPEN_DEVICE_LOGS";
export const DID_OPEN_DEVICE_GRAFANALINK = "INSPIFY_ADMIN.DID_OPEN_DEVICE_GRAFANALINK";
export const DID_SELECT_DEVICE = "DID_SELECT_DEVICE";
export const DID_SELECT_VERSION_TO_UPDATE = "DID_SELECT_VERSION_TO_UPDATE";
export const DID_CLICK_BUTTON = 'DID_CLICK_BUTTON';