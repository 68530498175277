import countries from "./countrycodejson";
import { Select } from "rbx";
import React from "react";
function SelectCountryDialCode({
  name,
  className,
  value,
  onChange,
  optionClassName,
}: {
  name?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  optionClassName?: string;
}) {
  return (
    <Select.Container className="selectInput-wrapper" fullwidth>
      <Select
        name={name}
        className={`selectInput ${className}`}
        value={value}
        onChange={onChange}
      >
        {countries
          .sort((a, b) => {
            const sort =
              +a.dial_code?.substring(1)?.replace(/\s+/g, "") -
              +b.dial_code?.substring(1)?.replace(/\s+/g, "");
            return sort || -1;
          })
          .map((country, i) => {
            const dialCodeFormated = country.dial_code
              ?.substring(1)
              ?.replace(/\s+/g, "");
            return (
              <Select.Option
                className={`selectInput-option ${optionClassName}`}
                key={i}
                value={dialCodeFormated}
              >
                {`+${dialCodeFormated}`}
              </Select.Option>
            );
          })}
      </Select>
    </Select.Container>
  );
}

export default SelectCountryDialCode;
