import Login from "../components/Login";
import React from "react";
import {
  Route,
  RouteProps,
  Redirect,
  HashRouter as Router,
} from "react-router-dom";
import AuthenticatedRoute from "./AuthenticatedRoute";
import { hasValidCredentials } from "../services/login";
import Dashboard from "./Dashboard";

function PrivateRoute({ children, ...rest }: RouteProps) {
  return (
    <Route
      {...rest}
      render={(props) =>
        hasValidCredentials() ? children : <Redirect to="/" />
      }
    />
  );
}

function App() {
  return (
    <Router>
      <Route
        exact
        path="/"
        component={() => (hasValidCredentials() ? <Dashboard /> : <Login />)}
      />
      <PrivateRoute path="/dashboard">
        <AuthenticatedRoute />
      </PrivateRoute>
    </Router>
  );
}

export default App;
