import React from "react";
import { Route, Switch } from "react-router-dom";
import Dashboard from "./Dashboard";
import BrandDetail from "./Devices";
import DeviceByBrand from "./DevicesByBrand";
import { useDispatch } from "react-redux";
import { getVersions } from "../redux/actions/devices";
import { getPageTypeFromHashPath, logPageView } from "../analytics";
import { PageView } from "../@types";
import HubUserByBrand from "./HubUsersByBrand";
import { checkValidSession } from "../services/login";
const AuthenticatedRoute = () => {
  const dispatch = useDispatch();
  const [page, setPage] = React.useState<PageView>(null);
  React.useEffect(() => {
    const hashChangeListener = () => {
      const newPage = getPageTypeFromHashPath(
        window.location.hash.replace("#/", "")
      );
      if (page !== newPage) {
        logPageView(newPage);
        setPage(newPage);
      }
    };

    if (window.location.hash) hashChangeListener();

    window.addEventListener("hashchange", hashChangeListener);

    return () => {
      window.removeEventListener("hashchange", hashChangeListener);
    };
  }, [page]);

  React.useEffect(() => {
    dispatch(getVersions());
  }, [dispatch]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      checkValidSession();
    }, 20000);
    return () => clearInterval(interval);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Switch>
      <Route exact path="/dashboard" component={Dashboard} />
      <Route path="/dashboard/devices/:id" component={DeviceByBrand} />
      <Route path="/dashboard/hubusers/:id" component={HubUserByBrand} />
      <Route path="/dashboard/devices" component={BrandDetail} />
      <Route path="/dashboard/hubusers" component={BrandDetail} />
    </Switch>
  );
};

export default AuthenticatedRoute;
