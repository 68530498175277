import { AxiosResponse } from "axios";
import { IPublisher, IPublisherPayload } from "../../@types";
import { apigClient, getS3Instance } from "../aws/apigClient";

export const getPublishers = async (): Promise<IPublisher[]> => {
  const client = await apigClient();
  const path = `/publishers/v2`;
  const publishersResult: AxiosResponse<IPublisherPayload[]> =
    await client.invokeApi({}, path, "GET", {});
  const s3 = await getS3Instance();
  const publishers: IPublisher[] = publishersResult.data
    .filter((p) => p.type === "brand")
    .map((p) => mapPublisherPayloadToPublisher(p, s3))
    .sort((a, b) => {
      if (a.alias.toLocaleLowerCase() > b.alias.toLocaleLowerCase()) {
        return 1;
      }
      if (a.alias.toLocaleLowerCase() < b.alias.toLocaleLowerCase()) {
        return -1;
      }
      return 0;
    });
  return publishers;
};

const bucketKeyExtractor = (imageUrl: string) => {
  const components = imageUrl.split("/");
  const bucket = components[0];
  components.shift();
  const key = components.join("/");
  return { key, bucket };
};
export const toSignedS3Url = (imageUrl?: string, s3?: AWS.S3) => {
  if (!imageUrl || !imageUrl?.includes("/")) {
    return;
  }

  const { key, bucket } = bucketKeyExtractor(imageUrl);
  const signedUrlExpireSeconds = 60 * 60 * 24;

  return s3?.getSignedUrl("getObject", {
    Bucket: bucket,
    Key: key,
    Expires: signedUrlExpireSeconds,
  });
};
export const mapPublisherPayloadToPublisher = (
  payload: IPublisherPayload,
  s3: AWS.S3
): IPublisher => {
  return {
    id: payload.ownerId,
    alias: payload.ownerAliasName,
    avatar: toSignedS3Url(payload.ownerAvatar_picture, s3),
    type: payload.type,
    parentPublisherId: payload.parentPublisherId,
  };
};
