import { Container, Field, Control, Column, Help } from "rbx";
import React from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  IEntitlement,
  IHubUser,
  IMainState,
  IStore,
  RecordingEntitlement,
} from "../../@types";

import { checkIfNumber, isValidEmail, isValidName } from "../../utils/common";
import TextInput from "../Common/TextInput";
import SelectInput from "../Common/SelectInput";
import Button from "../Common/Button";
import Modal from "../Common/Modal";
import { logEvent } from "../../analytics";
import {
  actionAddNewHubUser,
  actionGetHubUsersForBrand,
  actionUpdateNewHubUser,
} from "../../redux/actions/hubUsers";
import {
  BRAND_ID,
  DID_SELECT_HUB_USER_TO_UPDATE,
  DID_SUBMIT_NEW_HUB_USER,
  MEET_ADMIN_USER,
  MEET_USER,
  RECORDING_VISIBILITY,
  SALES,
  STORE_ID,
  USER_ADMIN,
} from "../../utils/constants";
import { toast } from "react-toastify";
import SelectCountryDialCode from "./SelectCountryDialCode";
import HubUserCommandModal from "./HubUserCommandModal";
import { HUB_USER_COMMANDS_MAP } from "../../services/hunUser";
import { Command } from "../../services/device";

interface IAddHubUserFormPristine {
  hubUserName: boolean;
  alias: boolean;
  email: boolean;
  storeId: boolean;
  first_name: boolean;
  last_name: boolean;
  full_name: boolean;
}

export interface IAddUserFormState {
  hubUserName: string;
  alias: string;
  email: string;
  storeId: string;
  first_name?: string;
  last_name?: string;
  full_name?: string;
  mobilePhoneCountryCode?: string;
  mobileNumber?: string;
  userType?: string;
  id?: string;
  recordingVisibility: RecordingEntitlement;
}

const AddHubUser = ({
  brandId,
  isOpen,
  onClose,
  selectedUser,
}: {
  brandId: string;
  isOpen: boolean;
  onClose: () => void;
  selectedUser?: string;
}) => {
  const dispatch = useDispatch();
  const stores: IStore[] = useSelector(
    (state: IMainState) => state?.stores?.stores
  )?.[brandId];
  const isSubmitting = useSelector(
    (state: IMainState) => state?.hubUsers?.isSubmitting
  );
  const newHubUser = useSelector(
    (state: IMainState) => state?.hubUsers?.newHubUser
  );

  const newHubUserError = useSelector(
    (state: IMainState) => state?.hubUsers?.error
  );
  const storeUserType = [
    { value: SALES, text: SALES },
    { value: USER_ADMIN, text: USER_ADMIN },
    { value: MEET_USER, text: MEET_USER },
    { value: MEET_ADMIN_USER, text: MEET_ADMIN_USER },
  ];
  const recordingVisibilityType = [
    { value: RecordingEntitlement.PRIVATE, text: "Private" },
    { value: RecordingEntitlement.SITE, text: "Site" },
    { value: RecordingEntitlement.BRAND, text: "Brand" },
  ];

  const hubUserCommandState = useSelector(
    (state: IMainState) => state?.hubUsers?.hubUserCommand
  );
  const lastExecutedCommand = hubUserCommandState?.executedCommand;
  const hubUsersForBrand =
    useSelector((state: IMainState) => state?.hubUsers?.hubUsers)?.[brandId] ||
    {};
  const hubUser = hubUsersForBrand?.[selectedUser];
  const [selectedCommand, setSelectedCommand] = React.useState<Command>(null);
  const [formState, setFormState] = React.useState<IAddUserFormState>({
    hubUserName: "",
    alias: "",
    storeId: "",
    email: "",
    first_name: "",
    last_name: "",
    full_name: "",
    mobilePhoneCountryCode: "+1",
    mobileNumber: "",
    userType: "sales",
    id: "",
    recordingVisibility: RecordingEntitlement.PRIVATE,
  });
  const handlePhoneNumberKeyPress = (e) => {
    if (!checkIfNumber(e?.target?.value) && e?.target?.value) {
      e.preventDefault();
      return;
    }
    const inputFieldName = e?.target?.name;
    const inputValue = e?.target?.value;
    setFormState({
      ...formState,
      [inputFieldName]: inputValue,
    });
  };
  const handleOnChangeText = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputFieldName = e?.target?.name;
    const inputValue = e?.target?.value;
    setFormState({
      ...formState,
      [inputFieldName]: inputValue,
    });
  };
  const [pristine, setPristine] = React.useState<IAddHubUserFormPristine>({
    hubUserName: true,
    alias: true,
    email: true,
    storeId: true,
    first_name: true,
    last_name: true,
    full_name: true,
  });

  const invalidHubUserName =
    !pristine.hubUserName && !isValidName(formState?.hubUserName);
  const invalidStoreId = !pristine.storeId && !isValidName(formState?.storeId);
  const invalidEmail = !pristine.email && !isValidEmail(formState?.email);
  const invalidHubUserAlias = !pristine.alias && !isValidName(formState?.alias);
  const invalidHubLastName =
    !pristine.last_name && !isValidName(formState?.last_name);
  const invalidHubFirstName =
    !pristine.first_name && !isValidName(formState?.first_name);
  const handleOnSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const inputValue = e?.target?.value;
    const inputFieldName = e?.target?.name;
    setPristine({
      ...pristine,
      [inputFieldName]: false,
    });
    if ([MEET_USER, MEET_ADMIN_USER].includes(inputValue)) {
      setFormState({
        ...formState,
        recordingVisibility: RecordingEntitlement.PRIVATE,
        [inputFieldName]: inputValue,
      });
    } else {
      setFormState({
        ...formState,
        [inputFieldName]: inputValue,
      });
    }
  };

  const resetForm = () => {
    setPristine({
      hubUserName: true,
      alias: true,
      email: true,
      storeId: true,
      first_name: true,
      last_name: true,
      full_name: true,
    });
    setFormState((state) =>
      Object.assign(
        {},
        {
          ...state,
          hubUserName: "",
          email: "",
          alias: "",
          first_name: "",
          last_name: "",
          full_name: "",
          mobilePhoneCountryCode: "+1",
          mobileNumber: "",
        }
      )
    );
  };

  const removePristine = () => {
    setPristine({
      hubUserName: false,
      alias: false,
      email: false,
      storeId: false,
      first_name: false,
      last_name: false,
      full_name: false,
    });
  };
  const handleClose = React.useCallback(() => {
    resetForm();
    onClose();
  }, [onClose]);

  const handleSave = () => {
    if (isSubmitting) {
      return;
    }
    removePristine();
    if (
      !isValidName(formState?.hubUserName) ||
      !isValidName(formState?.storeId) ||
      !isValidEmail(formState?.email) ||
      !isValidName(formState?.alias) ||
      !isValidName(formState?.first_name) ||
      !isValidName(formState?.last_name)
    ) {
      return;
    }

    logEvent(DID_SUBMIT_NEW_HUB_USER, DID_SUBMIT_NEW_HUB_USER, {
      ...formState,
    });
    const entitlements: IEntitlement[] = [
      {
        entitlementType: STORE_ID,
        uuid: formState.storeId,
      },
      {
        entitlementType: BRAND_ID,
        uuid: brandId,
      },
      {
        entitlementType: RECORDING_VISIBILITY,
        uuid: formState.recordingVisibility,
      },
      ...(formState?.userType === USER_ADMIN
        ? [
            {
              entitlementType: USER_ADMIN,
              uuid: formState.storeId,
            },
          ]
        : []),
    ];

    const hubUserPayload: IHubUser = {
      user_name: formState?.hubUserName,
      email: formState?.email,
      entitlements: entitlements,
      id: "",
      alias: formState?.alias,
      userType: (formState?.userType === USER_ADMIN
        ? SALES
        : formState?.userType
      ).toLowerCase(),
      first_name: formState?.first_name,
      last_name: formState?.last_name,
      full_name: formState?.first_name + " " + formState?.last_name,
      mobilePhoneCountryCode: formState?.mobilePhoneCountryCode,
      mobileNumber: formState?.mobileNumber,
    };
    dispatch(actionAddNewHubUser(hubUserPayload));
  };
  const handleUpdate = () => {
    if (isSubmitting) {
      return;
    }
    removePristine();
    if (
      !isValidName(formState?.hubUserName) ||
      !isValidName(formState?.storeId) ||
      !isValidEmail(formState?.email) ||
      !isValidName(formState?.alias) ||
      !isValidName(formState?.first_name) ||
      !isValidName(formState?.last_name)
    ) {
      return;
    }

    logEvent(DID_SELECT_HUB_USER_TO_UPDATE, DID_SELECT_HUB_USER_TO_UPDATE, {
      ...formState,
    });
    const currentEntitlementsWithoutRecording = hubUser[0].entitlements?.filter(
      (ent) =>
        ent.entitlementType !== RECORDING_VISIBILITY &&
        ent.entitlementType !== STORE_ID &&
        ent.entitlementType !== USER_ADMIN
    );
    const entitlements: IEntitlement[] = [
      ...currentEntitlementsWithoutRecording,
      {
        entitlementType: STORE_ID,
        uuid: formState.storeId,
      },
      {
        entitlementType: RECORDING_VISIBILITY,
        uuid: formState.recordingVisibility,
      },
      ...(formState?.userType === USER_ADMIN
        ? [
            {
              entitlementType: USER_ADMIN,
              uuid: formState.storeId,
            },
          ]
        : []),
    ];

    const hubUserPayload: IHubUser = {
      user_name: formState?.hubUserName,
      email: formState?.email,
      entitlements: entitlements,
      id: formState?.id,
      alias: formState?.alias,
      userType: (formState?.userType === USER_ADMIN
        ? SALES
        : formState?.userType
      ).toLowerCase(),
      first_name: formState?.first_name,
      last_name: formState?.last_name,
      full_name: formState?.first_name + " " + formState?.last_name,
      mobilePhoneCountryCode: formState?.mobilePhoneCountryCode,
      mobileNumber: formState?.mobileNumber,
    };
    dispatch(actionUpdateNewHubUser(hubUserPayload));
  };

  const storeSelectOptions = React.useMemo(() => {
    return stores?.map((store) =>
      Object.assign({ value: store?.id, text: store?.name })
    );
  }, [stores]);

  React.useEffect(() => {
    if (stores?.length > 0) {
      setFormState((state) =>
        Object.assign({}, { ...state, storeId: stores?.[0]?.id })
      );
    }
  }, [stores]);

  React.useEffect(() => {
    if (!isSubmitting && newHubUser) {
      handleClose();
      dispatch(actionGetHubUsersForBrand(brandId));
    }
  }, [isSubmitting, newHubUser, handleClose, dispatch, brandId]);

  React.useEffect(() => {
    if (newHubUserError)
      toast.error(
        newHubUserError?.["errorMessage"]
          ? newHubUserError?.["errorMessage"]
          : hubUser
          ? "Unable to update user"
          : "Unable to add user",
        {
          autoClose: 5000,
        }
      );
  }, [hubUser, newHubUserError]);

  React.useEffect(() => {
    if (newHubUser) {
      toast.success(hubUser ? "User updated" : "User Added", {
        autoClose: 5000,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newHubUser]);

  React.useEffect(() => {
    if (lastExecutedCommand) {
      onClose();
    }
  }, [lastExecutedCommand, onClose]);

  React.useEffect(() => {
    if (hubUser) {
      const type =
        hubUser[0].entitlements.find(
          (obj) => obj.entitlementType === USER_ADMIN
        )?.entitlementType ||
        hubUser[0]?.userType?.toUpperCase() ||
        "";

      const recordingUUID: RecordingEntitlement =
        (hubUser[0].entitlements.find(
          (obj) => obj.entitlementType === RECORDING_VISIBILITY
        )?.uuid as RecordingEntitlement) || RecordingEntitlement.PRIVATE;

      setFormState({
        hubUserName: hubUser[0].user_name,
        alias: hubUser[0].alias,
        storeId: hubUser[0].entitlements.find(
          (obj) => obj.entitlementType === STORE_ID
        ).uuid,
        email: hubUser[0].email,
        first_name: hubUser[0].first_name,
        last_name: hubUser[0].last_name,
        full_name: hubUser[0].full_name,
        mobilePhoneCountryCode: hubUser[0].mobilePhoneCountryCode,
        mobileNumber: hubUser[0].mobileNumber,
        userType: type,
        id: hubUser[0].id,
        recordingVisibility: recordingUUID,
      });
    }
  }, [hubUser]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={handleClose}
      title={hubUser ? "Update Hub User" : "Add New Hub User"}
      showClose
    >
      <Container id="addHubUserForm" className="add-device-form">
        <Column.Group className="add-device-form__column-group">
          <Column>
            <Field>
              <label className="input-label">Store</label>
              <Control>
                <SelectInput
                  name="storeId"
                  value={formState?.storeId || ""}
                  onChange={handleOnSelectChange}
                  options={storeSelectOptions}
                />
              </Control>
              {invalidStoreId && <Help color="danger">Required</Help>}
            </Field>
          </Column>
          <Column>
            <Field>
              <Column.Group className="user-column">
                <Column>
                  <Field>
                    <label className="input-label">User Type</label>
                    <Control>
                      <SelectInput
                        name="userType"
                        value={formState?.userType || ""}
                        onChange={handleOnSelectChange}
                        options={storeUserType}
                      />
                    </Control>
                    {invalidStoreId && <Help color="danger">Required</Help>}
                  </Field>
                </Column>
                <Column>
                  <Field>
                    <label className="input-label">Recording Visibility</label>
                    <Control>
                      <SelectInput
                        name="recordingVisibility"
                        value={formState?.recordingVisibility || ""}
                        onChange={handleOnSelectChange}
                        options={recordingVisibilityType}
                        disabled={[MEET_USER, MEET_ADMIN_USER].includes(
                          formState?.userType
                        )}
                      />
                    </Control>
                    {invalidStoreId && <Help color="danger">Required</Help>}
                  </Field>
                </Column>
              </Column.Group>
            </Field>
          </Column>
        </Column.Group>
        <Column.Group className="add-device-form__column-group">
          <Column>
            <Field>
              <label className="input-label">User Name</label>
              <Control>
                <TextInput
                  id="hubUserName"
                  name="hubUserName"
                  placeholder="Enter Hub User Name"
                  value={formState?.hubUserName}
                  onChange={handleOnChangeText}
                  disabledEdit={hubUser !== undefined}
                />
              </Control>
              {invalidHubUserName && <Help color="danger">Required</Help>}
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="input-label">Alias</label>
              <Control>
                <TextInput
                  id="alias"
                  name="alias"
                  placeholder="Hub User Name"
                  value={formState?.alias}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidHubUserAlias && <Help color="danger">Required</Help>}
            </Field>
          </Column>
        </Column.Group>
        <Column.Group className="add-device-form__column-group">
          <Column>
            <Field>
              <label className="input-label">First Name</label>
              <Control>
                <TextInput
                  id="first_name"
                  name="first_name"
                  placeholder="Enter First Name"
                  value={formState?.first_name}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidHubFirstName && <Help color="danger">Required</Help>}
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="input-label">Last Name</label>
              <Control>
                <TextInput
                  id="last_name"
                  name="last_name"
                  placeholder="Last Name"
                  value={formState?.last_name}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidHubLastName && <Help color="danger">Required</Help>}
            </Field>
          </Column>
        </Column.Group>
        <Column.Group className="add-device-form__column-group">
          <Column>
            <Field>
              <label className="input-label">Mobile Phone</label>
              <Column.Group className="user-column">
                <Column size={3}>
                  <Control>
                    <SelectCountryDialCode
                      name="mobilePhoneCountryCode"
                      value={formState?.mobilePhoneCountryCode}
                      onChange={handleOnSelectChange}
                    />
                  </Control>
                </Column>
                <Column>
                  <Control>
                    <TextInput
                      id="mobileNumber"
                      name="mobileNumber"
                      placeholder="Enter Mobile Phone"
                      value={formState?.mobileNumber}
                      onChange={handlePhoneNumberKeyPress}
                    />
                  </Control>
                </Column>
              </Column.Group>
            </Field>
          </Column>
          <Column>
            <Field>
              <label className="input-label">Email</label>
              <Control>
                <TextInput
                  id="email"
                  name="email"
                  placeholder="Email"
                  value={formState?.email}
                  onChange={handleOnChangeText}
                />
              </Control>
              {invalidEmail && <Help color="danger">Required</Help>}
            </Field>
          </Column>
        </Column.Group>

        {hubUser ? (
          <div className="add-device-form_footer">
            <div className="submit-add-new-device">
              <Button
                id="createNewHubUser"
                text="Update"
                className="submit-add-new-device"
                isLoading={isSubmitting}
                onClick={() => handleUpdate()}
              />
            </div>
            <div className="submit-add-new-device">
              <Button
                id="createNewHubUser"
                text="Delete"
                className="submit-add-new-device"
                onClick={() => {
                  setSelectedCommand(HUB_USER_COMMANDS_MAP.REMOVE_HUB_USER);
                }}
              />
            </div>
          </div>
        ) : (
          <div className="add-device-form_footer">
            <Button
              id="createNewHubUser"
              text="Submit"
              className="submit-add-new-device"
              isLoading={isSubmitting}
              onClick={() => handleSave()}
            />
          </div>
        )}
      </Container>
      {hubUser ? (
        <HubUserCommandModal
          command={selectedCommand}
          isOpen={selectedCommand !== null}
          onClose={() => setSelectedCommand(null)}
          hubUser={hubUser[0]}
        />
      ) : null}
      <style>
        {`
          .user-column {
            padding-top: 2px;
          }
        `}
      </style>
    </Modal>
  );
};

export default AddHubUser;
