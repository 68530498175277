import defaultState from "../initialState";
import { IAction, IHubUsers } from "../../../@types";
import {
  ADD_NEW_HUB_USER_FAILURE,
  ADD_NEW_HUB_USER_REQUEST,
  ADD_NEW_HUB_USER_SUCCESS,
  FAILED_FETCH_HUB_USERS_BY_BRAND,
  FETCHING_HUB_USERS_BY_BRAND,
  RESET_HUB_USER_COMMAND_STATE,
  RESET_NEW_HUB_USER_REQUEST,
  SELECT_BRAND_FOR_HUB_USER_PREVIEW,
  SEND_HUB_USER_COMMAND_FAILURE,
  SEND_HUB_USER_COMMAND_REQUEST,
  SEND_HUB_USER_COMMAND_SUCCESS,
  SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND,
} from "../../../utils/constants";

const hubUsersReducer = (
  state: IHubUsers = defaultState?.hubUsers,
  action: IAction<any>
) => {
  switch (action.type) {
    case FETCHING_HUB_USERS_BY_BRAND: {
      return { ...state, fetching: true, error: undefined };
    }
    case SUCCESSFUL_FETCH_HUB_USERS_BY_BRAND: {
      const { brandId, hubUsers } = action?.payload;
      return {
        ...state,
        fetching: false,
        error: undefined,
        hubUsers: { ...state.hubUsers, [brandId]: hubUsers },
      };
    }
    case FAILED_FETCH_HUB_USERS_BY_BRAND: {
      return { ...state, fetching: false, error: action.payload };
    }
    case SELECT_BRAND_FOR_HUB_USER_PREVIEW: {
      return {
        ...state,
        brandId: action?.payload,
      };
    }

    case ADD_NEW_HUB_USER_REQUEST: {
      return { ...state, isSubmitting: true, error: undefined };
    }
    case RESET_NEW_HUB_USER_REQUEST: {
      return {
        ...state,
        isSubmitting: false,
        error: undefined,
        newHubUser: undefined,
      };
    }
    case ADD_NEW_HUB_USER_SUCCESS: {
      return {
        ...state,
        isSubmitting: false,
        error: undefined,
        newHubUser: action?.payload,
      };
    }
    case ADD_NEW_HUB_USER_FAILURE: {
      return { ...state, isSubmitting: false, error: action.payload };
    }

    case SEND_HUB_USER_COMMAND_REQUEST: {
      return {
        ...state,
        hubUserCommand: {
          ...state?.hubUserCommand,
          isSubmitting: true,
          executedCommand: undefined,
          error: undefined,
        },
      };
    }
    case SEND_HUB_USER_COMMAND_SUCCESS: {
      return {
        ...state,
        hubUserCommand: {
          ...state?.hubUserCommand,
          isSubmitting: false,
          executedCommand: action?.payload,
          error: undefined,
        },
      };
    }
    case RESET_HUB_USER_COMMAND_STATE: {
      return {
        ...state,
        hubUserCommand: defaultState?.hubUsers?.hubUserCommand,
      };
    }
    case SEND_HUB_USER_COMMAND_FAILURE: {
      return {
        ...state,
        hubUserCommand: {
          ...state?.hubUserCommand,
          isSubmitting: false,
          executedCommand: undefined,
          error: action?.payload,
        },
      };
    }

    default:
      return state;
  }
};

export default hubUsersReducer;
