import { Help } from "rbx";
import React from "react";
import { IRack } from "../../@types";
import { CheckMP4Url } from "../../utils/common";
import TextInput from "../Common/TextInput";

const ShowcaseVideo = ({
  rack,
  addVideoLink,
  removeVideoLink,
}: {
  rack: IRack;
  addVideoLink: (videoLink: string) => void;
  removeVideoLink: (videoLink: string, index: number) => void;
}) => {
  const [videoLink, setVideoLink] = React.useState("");
  const [invalid, setInvalid] = React.useState(false);
  return (
    <>
      <br></br>
      <div className="row">
        <div className="col-10 input-video">
          <TextInput
            placeholder="Add video mp4 URL"
            onChange={(event) => {
              setVideoLink(event.target.value);
              setInvalid(false);
            }}
            value={videoLink}
            disabledEdit={!rack}
          />
          {invalid && <Help color="danger">Video URL should be mp4-URL</Help>}
        </div>
        <div className="col-2">
          <button
            className="btn common-button save-btn col-4"
            onClick={() => {
              if (CheckMP4Url(videoLink)) {
                addVideoLink(videoLink);
                setVideoLink("");
              } else {
                setInvalid(true);
              }
              setVideoLink("");
            }}
            disabled={!rack}
          >
            Add video mp4 url
          </button>
        </div>
      </div>
      <div className="overflow-auto selected-video" id="selected-video">
        <div className="card-group">
          {rack?.showcaseVideos?.map((video, i) => (
            <div key={i} className="text-video">
              <span className="selected-username">{`${video}`}</span>

              <span
                className="remove-video"
                onClick={() => removeVideoLink(video, i)}
              >
                x
              </span>
            </div>
          ))}
          {rack?.showcaseVideos?.length === 0 && (
            <div className="center">No videos added.</div>
          )}
        </div>
      </div>
      <p />
      <style>{`
      .input-video .help{
        height: 5px;
        margin-bottom: 0;
      }
          .center {
            text-align: center;
            width: 100%;
            color: #aaa;
          }
          .selected-username {
            padding: 0px 10px;
            font-weight: 500;
          }
          .remove-video {
            border: 1px solid #fff;
            border-radius: 100px;
            padding: 0px 2px 1px 2px;
          }
          .text-video {
            background: #ffffff11;
            padding: 5px 10px;
            margin: 4px 5px;
            border-radius: 5px;
            width: fit-content;
            height: fit-content;
            cursor: pointer;
            text-align: left;
          }
          .selected-member-img {
            width: 24px;
            height: 24px;
          }
          .selected-card {
            background: gray;
          }
          .selected-video {
            border-radius: 5px;
            border: 10px solid '#acc'
            padding: 4px;
            height: 30vh;
            margin-top:20px;
            background: #ffffff11;
          }
          
        `}</style>
    </>
  );
};

export default ShowcaseVideo;
