import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { IDevice, IVersion, IMainState } from "../../@types";
import { IconRefresh, IconUpdateVersion } from "../Common/Icons";
import Modal from "../Common/Modal";
import Button from "../Common/Button";
import { Command } from "../../services/device";
import { getVersions, sendDeviceCommand } from "../../redux/actions/devices";
import { getDeviceType } from "../../utils/device";
import { logEvent } from "../../analytics";
import {
  DID_EXECUTE_DEVICE_COMMAND,
  DID_REFRESH_LIST_APK,
  DID_SELECT_VERSION_TO_UPDATE,
} from "../../utils/constants";
import { DeviceType } from "./AddDeviceModal";
import { checkValidSession } from "../../services/login";
const DeviceUpdateModal = ({
  device,
  onClose,
  command,
  type,
}: {
  device: IDevice;
  onClose: () => void;
  command: Command;
  type: DeviceType;
}) => {
  const dispatch = useDispatch();
  const [selectedVersion, setSelectedVersion] = React.useState<IVersion>(null);
  const versionsMap = useSelector(
    (state: IMainState) => state?.devices?.versions
  );
  const deviceCommandState = useSelector(
    (state: IMainState) => state?.devices?.deviceCommand
  );

  const isSubmittingCommand = deviceCommandState?.isSubmitting;
  const deviceType = getDeviceType([device]);
  const [region, setRegion] = React.useState("sg");
  const versions: IVersion[] = versionsMap?.[type ? type : deviceType] || [];
  const onSelectVersion = (ver: IVersion) => {
    setSelectedVersion(ver);
  };

  const onConfirmCommand = () => {
    const download_apk_url =
      region === "cn" ? selectedVersion.chinaUrl : selectedVersion.url;
    const commandWithArguments: Command = {
      ...command,
      argumments: {
        download_apk_url,
        version: selectedVersion?.version,
      },
    };

    logEvent(DID_EXECUTE_DEVICE_COMMAND, DID_EXECUTE_DEVICE_COMMAND, {
      device: device?.deviceId,
      command: commandWithArguments,
      region,
    });

    dispatch(
      sendDeviceCommand(device?.deviceId, deviceType, commandWithArguments)
    );
  };
  return (
    <div className="update-modal-content">
      <div
        className="refresh"
        onClick={() => {
          logEvent(DID_REFRESH_LIST_APK, DID_REFRESH_LIST_APK, {
            commandType: command.type,
          });
          dispatch(getVersions());
          checkValidSession();
        }}
      >
        <IconRefresh />
      </div>
      {versions.map((ver, index) => (
        <div
          key={index}
          onClick={() => {
            logEvent(
              DID_SELECT_VERSION_TO_UPDATE,
              DID_SELECT_VERSION_TO_UPDATE,
              {
                deviceType,
                device: device?.deviceId,
                version: ver?.version,
              }
            );
            onSelectVersion(ver);
          }}
          className="update-modal-content-item"
        >
          {ver?.name}
          <IconUpdateVersion />
        </div>
      ))}
      <Modal
        isOpen={selectedVersion !== null}
        onClose={() => setSelectedVersion(null)}
        title={`Update ${type ? "Bolt" : deviceType}`}
        className="DeviceCommandModal"
        showClose
      >
        <div className="prompt-modal-content">
          <p>
            Are you sure you want to update to v{selectedVersion?.version} ?
          </p>
          <div className="swicher">
            <span>Singapore</span>
            <div className="form-check form-switch">
              <input
                className="form-check-input"
                type="checkbox"
                role="switch"
                checked={region === "cn"}
                onChange={(e) => {
                  setRegion(e.target.checked ? "cn" : "sg");
                }}
                id="regionSwitch"
              />
            </div>
            <span>China</span>
          </div>

          <div className="prompt-modal-content-footer">
            <Button
              id="Back"
              text="Back"
              border
              onClick={() => setSelectedVersion(null)}
              className="prompt-modal-content-footer-btn"
            />
            <Button
              id="Proceed"
              text="Proceed"
              isLoading={isSubmittingCommand}
              onClick={onConfirmCommand}
              className="prompt-modal-content-footer-btn"
            />
          </div>
        </div>
      </Modal>
      <style>
        {`
          .refresh {
            cursor: pointer;
            position: absolute;
            left: 30px;
            top: 50px;
            padding: 2px;
          }
          .refresh:hover {
            background-color: #ffffff22;
          }
          .swicher {
            display: flex;
            justify-content: center;
            gap: 20px;
            margin-bottom: 20px;
          }
          .form-switch input {
            width: 40px !important;
          }
        `}
      </style>
    </div>
  );
};

export default DeviceUpdateModal;
