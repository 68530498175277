import { Select } from "rbx";
import React from "react";

const SelectInput = ({
  name,
  className,
  value,
  onChange,
  options,
  optionClassName,
  disabled = false
}: {
  name?: string;
  className?: string;
  onChange?: (e: React.ChangeEvent<HTMLSelectElement>) => void;
  value?: string;
  options: any[];
  optionClassName?: string;
  disabled?:boolean;
}) => {
  return (
    <Select.Container className="selectInput-wrapper" fullwidth>
      <Select
        name={name}
        className={`selectInput ${className}`}
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        {options?.map((option, index) => (
          <Select.Option
            className={`selectInput-option ${optionClassName}`}
            key={index}
            value={option?.value}
          >
            {option?.text}
          </Select.Option>
        ))}
      </Select>
    </Select.Container>
  );
};

export default SelectInput;
