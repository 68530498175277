import React from "react";
import { IconSearch } from "./Icons";

const SearchInput = ({
  onChange,
  value,
  onKeyPress,
}: {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  value?: string;
  onKeyPress?: () => void;
}) => {
  return (
    <div className="searchInput">
      <IconSearch />
      <input
        placeholder="Search"
        id="search-input"
        value={value}
        onChange={onChange}
        onKeyPress={onKeyPress}
      />
    </div>
  );
};

export default SearchInput;
